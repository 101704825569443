import {Address, PaginationModel, Price} from '@spartacus/core';
import {Order} from '@spartacus/order/root';
import {Receiver, Schedule, SsabDeliveryMode, SsabOrderEntry} from './cart.model';
import {UserGroup} from '@spartacus/organization/administration/core';
import {SsabUnitOfMeasure} from "./product.model";
import {DateRange, ValueRange} from './misc.model';
import {SsabUser} from "./user.model";
import {DeliveryMode} from "@spartacus/cart/base/root";

export enum OrderTrackingTypes {
  Orders = 'orders',
  OrderItem = 'orderItems'
}

export enum OrderTrackingTables {
  Order = 'order',
  Mill = 'MILL',
  Documents = 'documents',
  TrackAndTrace = 'trackAndTrace',
  Feedback = 'feedback'
}

export enum FeedbackType {
  DELIVERY = 'DELIVERY',
  QUALITY = 'QUALITY',
  SERVICE = 'SERVICE'
}

export interface SsabOrderDocument {
  type?: string;
  number?: string;
  date?: string;
}

export interface SsabOrderHistory {
  pagination?: PaginationModel;
  orders?: SsabOrder[];
  allowedStatuses?: string[];
  allowedSalesChannels?: string[];
  allowedGrades?: string[];
  allowedPlants?: string[];
  thicknessMin?: number;
  thicknessMax?: number;
  allowedCustomers?: UserGroup[];
  /* @TODO check names */
  allowedCompanies?: UserGroup[];
  defaultCustomer?: UserGroup;
  allowedShipToCustomers?: UserGroup[];
  firstDate?: string;
  lastDate?: string;
  firstDeliveryDate?: string;
  lastDeliveryDate?: string;
}

export interface SsabOrder extends Order {
  entries: SsabOrderHistoryEntry[];
  salesChannel?: string;
  documents?: SsabOrderDocument[];
  gtmEntrydata: string;
  isOrderSplit?: boolean;
  externalOrderNumbersList?: string[];
  consignment?: boolean;
  unit?: UserGroup;
  totalDisplayWeight?: number;
  bundleSplitTotal?: Price;
  user?: SsabUser;
  deliveryMode?: SsabDeliveryMode;
  buyerAddress?: Address;
  payerAddress?: Address;
  billingAddress?: Address;
  orderConfirmationEmail?: string;
  b1OrderConfirmationEmail?: string;
  b1OrderConfirmationReceivers?: Receiver[];
  deliveryNoteEmail?: string;
  materialCertificateEmail?: string;
  freeTextForWarehouse?: string;
  orderSource?: string;
  allowFeedback?: boolean;
  feedbacks?: SsabOrderFeedbackForm[];
}

export interface SsabOrderHistoryEntry extends SsabOrderEntry {
  confirmedSchedules?: Schedule[];
  deliveries?: Delivery[];
  dimensions?: string;
  confirmedDate?: string;
  confirmedWeek?: string;
  estimatedDate?: string;
  estimatedWeek?: string;
  deliveredAmount?: number;
  readyToShipQuantity?: number;
  inProductionQuantity?: number;
  status?: string;
  delayed?: boolean;
  infoLine?: string;
  trackAndTrace?: SsabTrackAndTrace[];
}

export interface SsabTrackAndTrace {
  deliveryNote?: string;
  loadNumber?: string;
  eta?: string;
  status?: string;
  url?: string;
  unit?: string;
  quantity?: number;
  originCity?: string;
  originCountry?: string;
  destinationCity?: string;
  destinationCountry?: string;
}

export interface SsabOrderHistoryRequest {
  from?: number;
  size?: number;
  sorts?: Sort[];
  creationDate?: DateRange;
  deliveryIds?: string[];
  status?: string[];
  buyerIds?: string[];
  orderIdOrPoReference?: string;
  grades?: string[];
  thickness?: ValueRange;
  salesChannels?: string[];
  estimatedWeekDate?: DateRange;
  confirmedWeekDate?: DateRange;
  lateOrderNotification?: boolean;
  companyId?: string;
  consigneeId?: string;
}

export interface Delivery {
  amount?: number;
  date?: string;
  unit?: SsabUnitOfMeasure;
  infoLine?: string;
}

export interface Sort {
  field: string;
  descending: boolean;
}

export interface KeyValuePair {
  key: string;
  value: string;
}

export interface SsabOrderFeedbackForm {
  feedBacks: SsabOrderFeedbackData[]
  commentedAt: string;
  commmentedBy: UserGroup;
}

export interface SsabOrderFeedbackData {
  id: FeedbackType;
  comment: string;
  rating: number;
}
