<div class="ssab-modal-md ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title d-inline">
          {{ 'ssab.claims.status.modal.title'|cxTranslate }}
        </h3>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="dismiss('cancelled')"></i>
      </div>
      <div class="modal-body" [formGroup]="form">
        <p [innerHtml]="'ssab.claims.status.modal.description' | cxTranslate "></p>

        <div class="field-content">
          <h4>{{ 'ssab.claims.status.modal.currentStatus'|cxTranslate }}:</h4>
          <div class="field-value">
            <div class="form-check form-check-statuses mb-2 mb-lg-0 d-flex align-items-center">

                <input
                  id="{{currentStatus}}"
                  class="form-check-input"
                  type="radio"
                  value="{{currentStatus}}"
                  formControlName="newStatus"
                  (change)="changeStatus(currentStatus)"
                >
                <div class="form-check-label">
                  <span class="claims-status status-{{currentStatus}}">
                    <cx-icon [type]="iconTypes.CIRCLE" class="pr-1"></cx-icon>
                    {{ 'ssab.claims.status.' + currentStatus | cxTranslate }}
                  </span>

              </div>
            </div>
          </div>
        </div>
        <div class="field-content">
          <h4>{{ 'ssab.claims.status.modal.newStatus'|cxTranslate }}:</h4>
          <div class="field-value d-lg-flex justify-content-between">
            <ng-container *ngFor="let status of allowedStatuses">
              <div class="form-check form-check-statuses mb-2 mb-lg-0 d-flex align-items-center" *ngIf="status !== currentStatus">
                <input
                  id="{{status}}"
                  class="form-check-input"
                  type="radio"
                  value="{{status}}"
                  formControlName="newStatus"
                  (change)="changeStatus(status)"
                >
                <div class="form-check-label">
                  <span class="claims-status status-{{status}}">
                    <cx-icon [type]="iconTypes.CIRCLE" class="pr-1"></cx-icon>
                    {{ 'ssab.claims.status.' + status | cxTranslate }}
                  </span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="field-content" *ngIf="showInProcess">
          <label class="required">{{ 'ssab.claims.list.claimId.label'|cxTranslate }}</label>
          <div class="field-value">
            <ssab-cx-input
              [typeInput]="'text'"
              [idName]="'claimId'"
              [nameForm]="'claimId'"
              [parentForm]="form">
            </ssab-cx-input>
          </div>
        </div>
        <div class="field-content" *ngIf="showInProcess">
          <label>{{ 'ssab.claims.status.modal.customerMessage'|cxTranslate }}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="message"
              [nameForm]="'message'"
              [parentForm]="form"
              [isTextArea]="true"
              [labelPlaceholder]="'common.write'"
            ></ssab-cx-input>
          </div>
        </div>
        <div class="field-content" *ngIf="showEvaluated">
          <button class="btn btn-primary my-2" *ngIf="!showEvaluatedDocument" (click)="searchDocument()">
            {{ 'ssab.claims.status.modal.search8dDocument'|cxTranslate }}
          </button>
          <div class="evaluated-document d-flex align-items-start p-3 my-2" *ngIf="showEvaluatedDocument">
            <ng-container *ngIf="documents$|async as documents">
              <ng-container *ngFor="let document of documents">
                <i class="ssab-icon icon-general-PDF icon-primary mr-3"></i>
                <div class="flex-grow-1">
                  <h4>{{ 'ssab.claims.status.modal.8dDocument'|cxTranslate }}</h4>
                  <div class="field-content d-inline-block mb-0">
                    <label class="d-inline-block mb-0 mr-1">{{ 'ssab.claims.list.document.documentNumber.label'|cxTranslate }}:</label>
                    <span>{{ document.complaintNumber }}</span>
                  </div>
                  <div class="d-inline-block mx-2">|</div>
                  <div class="field-content d-inline-block mb-0">
                    <label class="d-inline-block mb-0 mr-1">{{ 'ssab.claims.list.document.documentDate.label'|cxTranslate }}:</label>
                    <span>{{ convertUIDate(document.documentDate)|cxDate: (getDateFormat()|async) }}</span>
                  </div>
                </div>
                <button class="btn btn-icon m-0 p-0" (click)="downloadClaimDocument(document)">
                  <span class="ssab-icon icon-system-download icon-primary"></span>
                </button>
              </ng-container>
            </ng-container>
          </div>
          <div class="field-value" *ngIf="showEvaluatedDocument">
            <div class="form-check">
              <input
                class="form-check-input position-relative"
                id="attach"
                [formControlName]="'attach'"
                type="checkbox">
              <label class="form-check-label pl-3 text-lg" [innerHtml]="'ssab.claims.status.modal.attach8dDocument' | cxTranslate"
                     for="attach"></label>
            </div>
          </div>
        </div>
        <div class="field-content" *ngIf="showEvaluated || showCompleted || showRejected">
          <label>{{ 'ssab.claims.status.modal.customerMessage'|cxTranslate }}</label>
          <div class="field-value">
            <ssab-cx-input
              idName="message"
              [nameForm]="'message'"
              [parentForm]="form"
              [isTextArea]="true"
              [labelPlaceholder]="'common.write'"
            ></ssab-cx-input>
          </div>
        </div>
        <div class="field-content" >
          <label>{{ 'ssab.claims.status.modal.customerContactDetails'|cxTranslate }}</label>
          <div class="field-value">
            {{claim.claimContactEmails.join(',')}}
          </div>
        </div>

        <div class="buttons d-flex justify-content-center">
          <button class="btn btn-link text-uppercase" (click)="dismiss('cancelled')">
            {{ 'common.cancel'|cxTranslate }}
          </button>
          <button class="btn btn-primary" (click)="update()">
            {{ 'common.update'|cxTranslate }}
          </button>
        </div>

        <div class="field-content mb-0 mt-4" *ngIf="showEvaluated && showEvaluatedDocument">
          <label class="d-inline-block mr-1">{{ 'ssab.claims.list.document.documentDate.label'|cxTranslate }}:</label>
          <span><!-- @TODO date --></span>
        </div>
      </div>
    </div>
  </div>
</div>
