import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {ActivatedRoute, Router} from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup, ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {SsabProductService} from "../../../service/product/ssab-product.service";
import {GlobalMessageService, GlobalMessageType, LanguageService, WindowRef} from "@spartacus/core";
import {EmailType} from "../../../model/misc.model";
import {CustomFormValidators} from "@spartacus/storefront";
import {SsabClaim, SsabClaimCreateRequest, SsabClaimMaterial} from "../../../model/claim.mode";
import {SsabUserService} from "../../../service/user/ssab-user.service";
import {BehaviorSubject, lastValueFrom, merge, Observable, of} from "rxjs";
import {B2BUnitOption, SsabUser} from "../../../model/user.model";
import {hideSearchOptions} from "../../shared/utils/functions/ssab-functions-utils";
import {catchError, debounceTime, distinctUntilChanged, filter, mergeMap, switchMap, tap, withLatestFrom} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";
import {SsabInputRangeComponent} from "../../../shared/input-range/ssab-input-range.component";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {SsabDocumentIdentifier} from "../../../model/document.model";

@Component({
  selector: 'ssab-cx-claim-request-form',
  templateUrl: './ssab-claim-request-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimRequestFormComponent implements OnDestroy, OnInit {
  @Input()
  initialClaim: SsabClaim;

  simulateClaim$: Observable<SsabClaim>;

  orders$: Observable<string[]>;
  customerIds$: Observable<B2BUnitOption[]>;
  minTermLength: number = 3;

  customerIdsInput$ = new BehaviorSubject<string>(undefined);
  ordersInput$ = new BehaviorSubject<string>(undefined);

  selectedCustomerId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  selectedOrderId$: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

  @Input()
  isRequestForm: boolean = true;
  partialClaim: boolean = true;
  isUrgent: boolean = false;

  uploadedFiles: File[] = [];
  user$: Observable<SsabUser>;

  selectedOrderLines: any[] = [];
  selectedMaterials: any[] = [];
  materials: any[] = [];

  form: UntypedFormGroup;

  maxFiles = 10;
  maxSize = 15 * 1024 * 1024; // 15 MB in bytes
  allowedFileTypes = [
    'image/jpeg', 'image/png', 'image/gif',
    'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/rtf', 'text/plain',
    'application/pdf',
    'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'video/mp4'
  ];

  constructor(protected productService: SsabProductService,
              protected router: Router,
              protected route: ActivatedRoute,
              protected fb: UntypedFormBuilder,
              protected cdr: ChangeDetectorRef,
              protected actions$: Actions,
              protected languageService: LanguageService,
              protected userService: SsabUserService,
              protected globalMessageService: GlobalMessageService,
              @Inject(DOCUMENT) private document: Document,
              protected windowRef: WindowRef) {
    this.user$ = this.userService.getUserDetails();
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      const claimId = params['claimId'];  // Get the query parameter
      this.initiateClaim({}, claimId);
    });
  }

  updateFormValidators(): void {
    this.updateFieldValidators('orderLines', this.selectedOrderLines.length > 0);
    this.updateFieldValidators('materialId', this.selectedMaterials.length > 0);
    this.updateFieldValidators('confirmFullClaim', this.partialClaim);
  }

  private updateFieldValidators(fieldName: string, clear: boolean): void {
    const control = this.form.get(fieldName);
    if (clear) {
      control.clearValidators();
    } else {
      control.setValidators([this.confirmFullClaimValidator()]);
    }
    control.updateValueAndValidity();
  }

  initiateAsyncValues(claim: SsabClaim): void {
    this.customerIds$ = merge(
      of(claim?.allowedCustomers),
      this.customerIdsInput$.pipe(
        filter(term => term !== undefined),
        distinctUntilChanged(),
        debounceTime(500),
        mergeMap(term =>
          this.userService.searchCustomersFromOrderHistory(term).pipe(
            catchError(() => of())
          )
        )
      )
    );

    if (claim.customer) {
      this.selectedCustomerId$.next(claim.customer.uid);
    }

    this.orders$ = merge(
      of(claim?.allowedOrders),
      this.selectedCustomerId$.pipe(
        filter(selectedCustomerId =>
          selectedCustomerId !== undefined
        ),
        switchMap(selectedCustomerId =>
          this.ordersInput$.pipe(
            distinctUntilChanged(),
            debounceTime(500),
            switchMap(term =>
              this.userService.searchOrdersForCustomer(term ?? '', selectedCustomerId).pipe(
                catchError(() => of())
              )
            )
          )
        )
      )
    );
  }

  onCustomerSelected(selectedCustomer: any): void {
    if (selectedCustomer) {
      this.selectedCustomerId$.next(selectedCustomer.uid);
      this.clearOrders();
    }
  }

  onOrderSelected(selectedOrder: any): void {
    if (selectedOrder) {
      this.selectedOrderId$.next(selectedOrder);
      this.selectedOrderLines = [];
      this.selectedMaterials = [];
      this.materials = [];
      this.partialClaim = true;
    }
  }

  noMaterialsFoundForOrderLines(): boolean {
    return this.isRequestForm && this.selectedOrderLines.length > 0 && this.materials.length === 0;
  }

  onUrgentChange(event: Event): void {
    this.isUrgent = (event.target as HTMLInputElement).checked;
  }

  hideSearchOptions(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    hideSearchOptions(divElement, inputRange);
  }

  clearCustomers(): void {
    this.customerIdsInput$.next(undefined);
  }

  onCancel() {
    this.router.navigate(['/claims-list']);
  }

  downloadPDF(event: MouseEvent): void {
    const element = document.getElementById('claimDetails');
    const button = event.target as HTMLElement;

    if (element) {
      html2canvas(element, {
        scale: 1,
        useCORS: true,
        onclone: (clonedDoc) => {
          Array.from(clonedDoc.getElementsByTagName('button')).forEach(btn => {
            if (btn.textContent === button.textContent) {
              btn.style.display = 'none';
            }
          });
        }
      }).then((canvas) => {
        const pdf = new jspdf.jsPDF();

        const imgData = canvas.toDataURL('image/png');
        const width = canvas.width;
        const height = canvas.height;

        const pdfWidth = 210; // A4 width in mm
        const pdfHeight = (height * pdfWidth) / width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        const blob = pdf.output('blob');
        const url = URL.createObjectURL(blob);

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.top = '-9999px';
        document.body.appendChild(iframe);

        iframe.src = url;

        iframe.onload = () => {
          iframe.contentWindow?.print();
        };
      });
    }
  }

  setClaimType(isPartial: boolean): void {
    this.partialClaim = isPartial;
    if (!isPartial) {
      this.selectedOrderLines = this.initialClaim?.claimEntries;
      const materials = this.selectedOrderLines.flatMap(orderLine => {
        return orderLine.materials.map(material => {
          material.quantityControl = new FormControl('', Validators.required);
          material.unitControl = new FormControl('', Validators.required);
          return material;
        });
      });
      this.selectedMaterials = materials;
      this.materials = materials;
      this.updateFormValidators();
    } else {
      this.selectedOrderLines = [];
      this.selectedMaterials = [];
    }
  }

  clearOrders(): void {
    this.form.controls['orderNumber'].reset();
    this.selectedMaterials = [];
    this.selectedOrderLines = [];
    this.materials = [];
    this.ordersInput$.next('');
  }

  initiateClaim(params: SsabClaimCreateRequest, claimId: string): void {
    if (this.isRequestForm) {
      this.simulateClaim$ =
        this.selectedOrderId$.pipe(
          withLatestFrom(this.selectedCustomerId$),
          switchMap(([orderNumber, customer]) =>
            this.userService.simulateClaim(({...params, customer, orderNumber, claimId}))
              .pipe(
                tap(response => this.initiateForm(response, orderNumber)),
                catchError(error => {
                  console.error('Error from backend:', error);
                  return of({} as SsabClaim);
                })
              )
          )
        );
    } else {
      this.simulateClaim$ = of(this.initialClaim);
      this.initiateForm(this.initialClaim, null);
    }
  }

  onSaveDraft(): void {
    // Remove all validators and set only orderNumber as required cause of the draft
    this.clearAllValidators();
    this.form.get('orderNumber').setValidators([Validators.required]);
    this.form.get('orderNumber').updateValueAndValidity();

    if (this.form.valid) {
      this.userService.createClaim(this.getFormData(true)).subscribe(
        response => {
          this.router.navigate(['/claims-list', response.code]);
        }
      );
    } else {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.submitError',
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }

  clearAllValidators(): void {
    Object.keys(this.form.controls).forEach(controlName => {
      this.form.get(controlName).clearValidators();
      this.form.get(controlName).updateValueAndValidity();
    });
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.userService.createClaim(this.getFormData(false)).subscribe(
        response => {
          this.router.navigate(['/claims-list', response.code]);
        }
      );
    } else {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.submitError',
        },
        GlobalMessageType.MSG_TYPE_ERROR
      );
    }
  }


  getClaimRequest(draft: boolean): SsabClaimCreateRequest {
    const claimEntries = this.selectedOrderLines.map(orderLine => {
      const relatedMaterials = orderLine.materials.filter(material =>
        this.selectedMaterials.some(selectedMaterial => selectedMaterial.materialId === material.materialId)
      ).map(material => ({
        materialId: material.materialId,
        quantity: material.quantityControl?.value || '',
        unit: material.unitControl?.value || '',
      }));

      return {
        orderLine: orderLine.orderLine,
        orderLineName: orderLine.orderLineName,
        materials: relatedMaterials,
      };
    });

    //Add custom materials
    if (claimEntries.length > 0) {
      claimEntries[0].materials.push(
        ...this.selectedMaterials
          .filter(material => material.custom)
          .map(material => ({
            materialId: material.materialId,
            quantity: material.quantityControl?.value || '',
            unit: material.unitControl?.value || '',
          }))
      );
    }

    return {
      orderNumber: this.form?.controls?.orderNumber?.value,
      partialClaim: this.partialClaim,
      contactEmails: this.getContactEmails().controls.map(control => control.value).filter(value => value !== null),
      technicalContactEmail: this.form?.controls?.technicalContact?.value,
      claimSubject: this.form?.controls?.claimSubject?.value,
      defectType: this.form?.controls?.defectType?.value,
      defectedArea: this.form?.controls?.defectedArea?.value,
      claimReason: this.form?.controls?.claimReason?.value,
      purchaseOrderNumber: this.form?.controls?.poNumber?.value,
      urgent: this.isUrgent,
      claimId: this.initialClaim?.code,
      customerName: this.form?.controls?.customerName?.value,
      urgencyReason: this.form?.controls?.urgentReason?.value,
      requestedCompensation: this.form?.controls?.requestedCompensation?.value,
      customerReferenceId: this.form?.controls?.referenceId?.value,
      draft: draft,
      customer: this.form?.controls?.customer?.value,
      claimEntries,
    } as SsabClaimCreateRequest;
  }

  getFormData(draft: boolean): FormData {
    const formData = new FormData();

    const claimRequest = this.getClaimRequest(draft);
    formData.append('claimRequest', JSON.stringify(claimRequest));

    this.uploadedFiles.forEach(file => {
      formData.append('files', file, file.name);
    });

    return formData;
  }

  editDraft(): void {
    this.router.navigate(['/claim-request'], {queryParams: {claimId: this.initialClaim?.code}});
  }

  deleteDraft(): void {
    this.userService.deleteClaim(this.initialClaim?.code).subscribe(() => {
      this.router.navigate(['/claims-list'], { replaceUrl: true });
      window.location.reload();
    });
  }

  addOrderLine(orderLine: any): void {
    if (!this.selectedOrderLines.some(line => line.orderLine === orderLine.orderLine)) {
      this.selectedOrderLines.push(orderLine);
      this.materials = [...this.materials, ...orderLine.materials];
      this.form.get('orderLines').reset();
      this.updateFormValidators();
    }
  }

  removeOrderLine(orderLine: any): void {
    if (!this.partialClaim) {
      this.partialClaim = true;
    }
    this.selectedOrderLines = this.selectedOrderLines.filter(line => line.orderLine !== orderLine.orderLine);

    this.materials = this.materials.filter(material =>
      !orderLine.materials.some(relatedMaterial => relatedMaterial.materialId === material.materialId)
    );

    this.selectedMaterials = this.selectedMaterials.filter(material =>
      !orderLine.materials.some(relatedMaterial => relatedMaterial.materialId === material.materialId)
    );

    this.updateFormValidators();
  }

  addMaterial(material: any): void {
    if (!this.selectedMaterials.some(mat => mat.materialId === material.materialId)) {
      material.quantityControl = new FormControl('1', Validators.required);
      material.unitControl = new FormControl('EA', Validators.required);
      this.selectedMaterials.push(material);
      this.form.get('materialId').reset();
      this.updateFormValidators();
    }
  }

  removeMaterial(material: any): void {
    if (!this.partialClaim) {
      this.partialClaim = true;
    }
    const index = this.selectedMaterials.findIndex(mat => mat.materialId === material.materialId);
    if (index !== -1) {
      this.selectedMaterials.splice(index, 1);
      this.updateFormValidators();
      this.form.get('materialId').reset();
    }
  }

  initiateForm(claim: SsabClaim, orderNumber: string): void {
    this.initiateAsyncValues(claim);
    this.initialClaim = claim;
    this.form = this.fb.group({
      customer: [claim.customer?.uid, [Validators.required]],
      orderNumber: [claim.orderNumber, [Validators.required]],
      poNumber: [claim.purchaseOrderNumber, []],
      partialClaim: [false, [Validators.required]],
      orderLines: [null, [Validators.required]],
      materialId: [null, [Validators.required]],
      customerName: [null, []],
      contactEmails: this.fb.array([]),
      salesContact: [claim.salesContactEmail, []],
      technicalContact: [null, [
        control => control.value ? Validators.email(control) : null
      ]],
      deliveryAddress: [null, []],
      deliveryChannel: [null, []],
      claimSubject: [claim.claimSubject, [Validators.required]],
      defectType: [claim.defectType, [Validators.required]],
      defectedArea: [claim.defectedArea, [Validators.required]],
      claimReason: [claim.claimReason, [Validators.required]],
      urgent: [claim.urgent, []],
      urgentReason: [claim.urgencyReason, []],
      requestedCompensation: [claim.requestedCompensation, []],
      referenceId: [claim.customerReferenceId, []],
      confirmFullClaim: [false, [this.confirmFullClaimValidator()]],
    });

    if (claim.draft && !orderNumber) {
      this.partialClaim = claim.partialClaim;
      this.selectedOrderLines = this.initialClaim?.claimEntries || [];
      this.initialClaim.claimEntries = [...this.initialClaim.draftEntries];
      const mats = this.selectedOrderLines.flatMap(orderLine => {
        return orderLine.materials.map(material => {
          material.quantityControl = new FormControl(material.quantity, Validators.required);
          material.unitControl = new FormControl(material.unit, Validators.required);
          return material;
        });
      });
      this.selectedMaterials = mats;
      this.materials = mats;
      this.updateFormValidators();
    }

    if (claim.draft && claim.claimAttachments) {
      this.buildFileArrayFromAttachments(claim.claimAttachments);
    }

    if (claim.claimContactEmails?.length > 0) {
      claim.claimContactEmails?.forEach(email => {
        this.addContactEmail(email);
      });
    } else {
      this.addContactEmail(null);
    }
  }

  async buildFileArrayFromAttachments(claimAttachments: SsabDocumentIdentifier[]): Promise<void> {
    const filePromises = claimAttachments.map(async (attachment) => {
      const data = await lastValueFrom(this.userService.downloadDocument(attachment));
      const blob = new Blob([data], { type: attachment.fileMime });
      return new File([blob], attachment.fileName, { type: attachment.fileMime });
    });

    this.uploadedFiles = await Promise.all(filePromises);
  }

  confirmFullClaimValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true ? null : { confirmFullClaimRequired: true };
    };
  }

  addContactEmail(value: string): void {
    this.getContactEmails().push(new FormControl(value, [CustomFormValidators.emailValidator]));
  }

  addCustomMaterial(): void {
    const material = {
      custom: true,
      materialId: '',
      quantity: 1,
      unit: 'EA',
    };
    this.addMaterial(material);
    this.materials = [];
  }

  updateMaterialForItem(material: SsabClaimMaterial, newValue: string) {
    material.materialId = newValue;
  }

  removeContactEmail(index: number): void {
    this.getContactEmails().removeAt(index);
  }

  getContactEmails(): FormArray {
    return this.form.controls['contactEmails'] as FormArray;
  }

  ngOnDestroy(): void {
    this.customerIdsInput$.complete();
    this.ordersInput$.complete();
    this.selectedOrderId$.complete();
    this.selectedCustomerId$.complete();
  }


  protected readonly EmailType = EmailType;

  onFilesSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files) {
      const files = Array.from(input.files);
      this.addFiles(files);
    }
  }

  addFiles(newFiles: File[]): void {
    if (this.uploadedFiles.length + newFiles.length > this.maxFiles) {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.uploadErrorMaxFiles',
          params: {maxFiles: this.maxFiles}
        },
        GlobalMessageType.MSG_TYPE_ERROR,
      );
      return;
    }

    const validFiles = newFiles.filter(file => this.validateFile(file));
    const invalidFiles = newFiles.length - validFiles.length;

    if (invalidFiles > 0) {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.uploadErrorInvalidFiles',
          params: {invalidFiles: invalidFiles}
        },
        GlobalMessageType.MSG_TYPE_ERROR,
      );
    }

    this.uploadedFiles = [...this.uploadedFiles, ...validFiles];
  }

  validateFile(file: File): boolean {
    const isValidType = this.allowedFileTypes.includes(file.type);
    const isValidSize = file.size <= this.maxSize;

    if (!isValidType) {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.uploadErrorInvalidType',
          params: {fileName: file.name}
        },
        GlobalMessageType.MSG_TYPE_ERROR,
      );
    }
    if (!isValidSize) {
      this.globalMessageService.add(
        {
          key: 'ssab.claim.request.uploadErrorInvalidSize',
          params: {fileName: file.name}
        },
        GlobalMessageType.MSG_TYPE_ERROR,
      );
    }

    return isValidType && isValidSize;
  }

  removeFile(file: File): void {
    const index = this.uploadedFiles.indexOf(file);
    if (index >= 0) {
      this.uploadedFiles.splice(index, 1);
    }
  }

  getFileUrl(file: File): string {
    return URL.createObjectURL(file);
  }
}
