import {BaseSite, BaseStore, OccEndpoint, ProductSearchPage, SearchConfig, SearchQuery} from '@spartacus/core';
import {GlobalMessageType} from '@spartacus/core/src/global-message/models/global-message.model';
import {SearchBoxConfig, SearchCriteria} from '@spartacus/storefront';
import {DocumentTypes} from "./document.model";
import {PaginationModel, SortModel} from "@spartacus/core/src/model/misc.model";
import {Product} from "@spartacus/core/src/model/product.model";
import {Breadcrumb, Facet, SearchState, SpellingSuggestion} from "@spartacus/core/src/model/product-search.model";

export interface SsabBaseSite extends BaseSite {
  azureLogin?: boolean;
  commerceLogin?: boolean;
  cdcLogin?: boolean;
  ssoLogin?: boolean;
  azureAuthority?: string;
  azureClientId?: string;
  baseStore?: SsabBaseStore;
}

export interface SsabBaseStore extends BaseStore {
  daysInStockConsignmentThreshold?: number;
  hiddenDocumentTypes?: DocumentTypes[];
}

export interface SsabNotification {
  uid: string;
  content: string;
}

export interface DateRange {
  from: string;
  to: string;
}

export interface Sort {
  field: string;
  descending: boolean;
}

export interface HeadTable {
  field: string;
  label?: string;
  isNotSortable?: boolean;
  tooltipInfo?: string;
  tooltipLeft?: boolean;
  className?: string;
  labelClass?: string;
}

export interface ValueRange {
  from?: number;
  to?: number;
}

export interface SsabSearchCriteria extends SearchCriteria {
  previousPageSize?: number;
}

export interface SsabSearchConfig extends SearchConfig {
  previousPageSize?: number;
  disableSpinner?: boolean;
  suggestiveMode?: boolean;
}

export interface SsabSearchQuery extends SearchQuery {
  downloadProductQuery?: string;
}

export interface SsabSearchBoxConfig extends SearchBoxConfig {
  suggestiveMode?: boolean;
}

export enum CalendarNavigationTypes {
  None = 'none',
  Select = 'select',
  Arrows = 'arrows'
}

export enum GlobalLogin {
  GlobalBaseSite = 'global',
  GlobalLanguage = 'en',
  GlobalCurrency = 'EUR',
  HomePageID = 'homepage',
  AsmActive = 'asm_enabled',
  BaseSiteActive = 'baseSiteActive',
  SessionActiveTime = 'sessionActiveTime',
  SpartacusCurrency = 'spartacus⚿⚿currency'
}

export enum EmailType {
  orderConfirmation = 'orderConfirmationEmail',
  deliveryNote = 'deliveryNoteEmail',
  materialCertificate = 'materialCertificateEmail',
  b1OrderConfirmation = 'b1OrderConfirmationEmail'
}

export enum EloquaForms {
  DocolDownload = 'SSAB_Customer_Portal_Docol_Download',
  Login = 'SSAB_Customer_Portal_Login',
  StockSearch = 'SSAB_Customer_Portal_Stock',
  Certificate = 'SSAB_Customer_Portal_Certificate',
  Invoice = 'SSAB_Customer_Portal_Invoice',
  Transports = 'SSAB_Customer_Portal_Transport_Documents',
  Confirmation = 'SSAB_Customer_Portal_Order_Confirmation',
  DocolSearch = 'SSAB_Customer_Portal_Docol_Search'
}

export enum TablesTypes {
  MaterialData = 'materialData',
  PreviousOrders = 'previousOrders',
  CoilsWithoutStock = 'CoilsWithoutStock',
  CoilsWithStock = 'CoilsWithStock',
  Claims_Internal = 'Claims_Internal',
  Claims_External = 'Claims_External',
  ClaimsDocuments = 'ClaimsDocuments'
}

export interface SsabGlobalConfiguration {
  oneTrust?: boolean;
  oneTrustDomain?: string;
  checkout?: SsabCheckoutConfiguration;
  stripNarrowAvailable?: boolean;
  cdcApiKey: string;
  consignmentAvailable?: boolean;
  otherServicesAvailable?: boolean;
  elqSiteID?: string;
  elqSuffixForm?: string;
  elqCookieWrite?: string;
  elqUrl?: string;
  elqUseFirstPartyCookie?: string;
  elqSrc?: string;
  showCdcProfileUpdate?: boolean;
  displayInches?: boolean;
  showTermsAndConditionsPage?: boolean;
  dateFormat?: string;
  dateTimeFormat?: string;
  showCustomerSpecificStockDownload?: boolean;
}

export interface SsabCheckoutConfiguration {
  showDeliveryMode?: boolean;
  showAddNewAddressForDelivery?: boolean;
  showWarehousePhoneNumber?: boolean;
  showUnloadingMethod?: boolean;
  showFreeTextForWarehouse?: boolean;
  showFreeTextForTransportation?: boolean;
  showFreeTextForTransportationPickup?: boolean;
  showPdfTransportationPickup?: boolean;
  readonlyInvoicingAddress?: boolean;
  showFreeTextForInvoice?: boolean;
  stretchContactInformation?: boolean;
  showMaterialCertificateEmail?: boolean;
  allowedPostalcodePrefixForNewAddress?: string[];
  showDeliveryNoteEmailSection?: boolean;
  purchaseOrderNumberMandatory?: boolean;
}

export class Alert {
  id: string;
  type: GlobalMessageType;
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  fade: boolean;
  hidePages: string[];
  allowClose: boolean;
  extraInfoMessage: string;

  constructor(init?: Partial<Alert>) {
    Object.assign(this, init);
  }
}

declare module '@spartacus/core' {
  interface OccEndpoints {
    refreshEmulatedUser?: string | OccEndpoint;
    getGTMProducts?: string | OccEndpoint;
    getGTMCartEntries?: string | OccEndpoint;
    getGTMConfirmationEntries?: string | OccEndpoint;
    getGTMContactSalesEntries?: string | OccEndpoint;
    getUser?: string | OccEndpoint;
    sendStockAvailabilityRequest?: string | OccEndpoint;
    listContactSalesProductLists?: string | OccEndpoint;
    createContactSalesProductList?: string | OccEndpoint;
    getGlobalConfiguration?: string | OccEndpoint;
    baseSitesForConfig?: string | OccEndpoint;
    subscribeProductAvailability?: string | OccEndpoint;
    selectUnit?: string | OccEndpoint;
    selectUnitNoCart?: string | OccEndpoint;
    searchUnit?: string | OccEndpoint;
    getSelectedUnit?: string | OccEndpoint;
    getCertificateDocuments?: string | OccEndpoint;
    getOrderConfirmationDocuments?: string | OccEndpoint;
    getInvoiceDocuments?: string | OccEndpoint;
    getTransportDocuments?: string | OccEndpoint;
    downloadDocument?: string | OccEndpoint;
    downloadDocuments?: string | OccEndpoint;
    downloadAllDocuments?: string | OccEndpoint;
    relatedDocuments?: string | OccEndpoint;
    delayedOrderEmail?: string | OccEndpoint;
    translationBundleVersion?: string | OccEndpoint;
    getNotifications?: string | OccEndpoint;
    dismissNotification?: string | OccEndpoint;
  }
}

export interface ExtraMessages {
  successMessage: string;
  errorMessage: string;
}
