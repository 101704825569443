import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SsabUser} from "../../model/user.model";
import {SsabUserService} from "../../service/user/ssab-user.service";
import {ActivatedRoute, Params, Router} from '@angular/router';
import {CalendarNavigationTypes, DateRange, Sort, TablesTypes} from "../../model/misc.model";
import {SsabInputRangeComponent} from "../../shared/input-range/ssab-input-range.component";
import {hideSearchOptions, isSearchOptionOpen, openCloseSpinner, toggleSearchOption} from '../shared/utils/functions/ssab-functions-utils';
import {DOCUMENT} from '@angular/common';
import {NgSelectComponent} from '@ng-select/ng-select';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SsabNgbDateParserFormatter} from "../shared/datepicker/ssab-datepicker-parser-formatter";
import {SsabClaimList, SsabClaimRequest} from "../../model/claim.mode";
import {catchError, filter} from "rxjs/operators";
import {DateUtils} from "../../shared/util/date-utils";
import {LanguageService, WindowRef} from "@spartacus/core";

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_SORT: Sort = {field: 'claimCreationDate', descending: true};

@Component({
  selector: 'ssab-cx-claims-search',
  templateUrl: './ssab-claims.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimsListComponent implements OnDestroy, OnInit {
  searchForm: UntypedFormGroup;
  claims$: Observable<SsabClaimList>;

  assigneesInput$ = new BehaviorSubject<string>(null);
  customerIdsInput$ = new BehaviorSubject<string>(null);
  deliveryChannelsInput$ = new BehaviorSubject<string>(null);
  countriesInput$ = new BehaviorSubject<string>(null);

  user$: Observable<SsabUser>;
  @ViewChild('claimSearch') claimSearch: ElementRef<HTMLElement>;

  activeSort: Sort = DEFAULT_SORT;
  currentPage = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  showHideFilter = true;
  calendarNavigationTypes = CalendarNavigationTypes;
  detailsOpen = false;
  subscriptions = new Subscription();
  halfYearBack = new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDay());

  constructor(
    protected cdr: ChangeDetectorRef,
    private router: Router,
    protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
    protected fb: UntypedFormBuilder,
    protected userService: SsabUserService,
    private activatedRoute: ActivatedRoute,
    protected language: LanguageService,
    protected winRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.user$ = this.userService.getUserDetails();
    this.initializeForm();
  }

  ngOnInit(): void {
    this.getParametersFromUrl();
    this.initiateSearch();
  }

  getParametersFromUrl(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.pageSize) {
          this.pageSize = +params.pageSize;
        }
        if (params.currentPage) {
          this.currentPage = params.currentPage;
        }
        Object.keys(this.searchForm.controls).forEach(key => {
          if (key.includes('Date') && params[key]) {
            this.searchForm.controls[key].setValue(new Date(params[key]));
          } else {
            if (key.includes('status') && params[key]) {
              let paramsArray = params[key].toString().split(',');
              if (this.searchForm.controls[key].value) {
                paramsArray.push(...this.searchForm.controls[key].value);
              }
              this.searchForm.controls[key].setValue(paramsArray);
            } else {
              if (params[key]) {
                this.searchForm.controls[key].setValue(params[key]);
              }
            }
          }
        });
        if (params.sort) {
          this.activeSort = {
            descending: params.sortDir === 'true', field: params.sort
          }
        }
      })
    );
  }

  initiateSearch(): void {
    openCloseSpinner(this.document, true);
    this.claims$ = this.userService.getClaimDocuments(this.getClaimRequest())
      .pipe(
        filter(Boolean),
        catchError(() => {
          openCloseSpinner(this.document, false);
          return of({});
        }), // empty on error
      );
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  clearCustomers(): void {
    this.customerIdsInput$.next(undefined);
  }

  clearAssignees(): void {
    this.assigneesInput$.next(undefined);
  }

  clearDeliveryChannels(): void {
    this.deliveryChannelsInput$.next(undefined);
  }

  clearCountries(): void {
    this.countriesInput$.next(undefined);
  }

  showHideFilterOptions(): void {
    const backgroundImage = this.document.querySelector('ssab-cx-background-image') as HTMLElement;
    backgroundImage.classList.add('cx-image-filters');
    if (backgroundImage.classList.contains('cx-image-filters')) {
      if (this.claimSearch?.nativeElement != null) {
        (backgroundImage.querySelector('cx-media') as HTMLElement).style.height = '530px';
        (backgroundImage.querySelector('cx-media img') as HTMLElement).style.height = '';
      } else {
        (backgroundImage.querySelector('cx-media') as HTMLElement).style.height = '530px';
      }
    } else {
      (backgroundImage.querySelector('cx-media') as HTMLElement).style.height = '';
    }
    this.showHideFilter = !this.showHideFilter;
    this.cdr.detectChanges();
  }

  hideFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-claims-search')
      .querySelector('.cx-claims-form');
    innerSearch.classList.add('d-none');
    const body = ($event.target as HTMLElement)
      .closest('body');
    body.classList.remove('no-scroll');
  }

  showFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-claims-search')
      .querySelector('.cx-claims-form.d-none');
    innerSearch.classList.remove('d-none');
    const body = ($event.target as HTMLElement)
      .closest('body');
    body.classList.add('no-scroll');
  }

  isInvalid(fieldName: string): boolean {
    return this.searchForm.get(fieldName) && this.searchForm.get(fieldName).invalid;
  }

  hideSearchOptions(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    hideSearchOptions(divElement, inputRange);
  }

  hideMultiSearchOptions(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    hideSearchOptions(divElement, inputRange);
    const selectAllCheckbox = this.document.querySelector('.select-all-items-checkbox') as HTMLInputElement;
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = true;
      this.removeMultiSelectHighlight();
    }
  }

  hideSearchOptionsDesktop(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    if (!this.isMobile()) {
      hideSearchOptions(divElement, inputRange);
    }
  }

  removeMultiSelectHighlight(): void {
    const markedElement = this.document.querySelector('.ng-option.ng-option-marked');
    if (markedElement) {
      markedElement.classList.remove('ng-option-marked');
    }
  }

  toggleFilter(divElement: HTMLDivElement): void {
    if ((this.isMobile() && (this.isCalendar(divElement))) || !(this.isMobile() && this.isFilterOpen(divElement))) {
      toggleSearchOption(divElement);
    }
  }

  isFilterOpen(divElement: HTMLDivElement): boolean {
    return isSearchOptionOpen(divElement);
  }

  isCalendar(divElement: HTMLDivElement): boolean {
    return divElement?.querySelector('ssab-datepicker-input') !== null;
  }

  isMobile(): boolean {
    return this.winRef.nativeWindow.matchMedia('only screen and (max-width: 760px)').matches;
  }

  toggleMultiSelectFilter(divElement: HTMLDivElement, currentSelection: any): void {
    if (!(this.isMobile() && this.isFilterOpen(divElement))) {
      toggleSearchOption(divElement);
    }

    const selectAllCheckbox = this.document.querySelector('.select-all-items-checkbox') as HTMLInputElement;
    const isAnySelected = currentSelection.selectedItems.length > 0;

    if (selectAllCheckbox) {
      selectAllCheckbox.checked = !isAnySelected;
    }
  }

  resetSelect(ngSelectComponent: NgSelectComponent): void {
    ngSelectComponent.clearModel();
    ngSelectComponent.close();
  }

  resetMultiSelect(ngSelectComponent: NgSelectComponent, event: Event): void {
    const selectAllCheckbox = event.target as HTMLInputElement;
    ngSelectComponent.clearModel();
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = true;
    }
  }

  convertDate(d: any): NgbDateStruct {
    if (d) {
      if (d.year && d.month && d.day) {
        return d;
      }
      return this.ngbDateParserFormatter.parse(d);
    }
    return null;
  }

  sort(sort: Sort): void {
    this.activeSort = sort;
    this.search();
  }

  search(): void {
    const queryParams: Params = this.getQueryParams();
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null || (Array.isArray(queryParams[key]) && queryParams[key].length == 0)) {
        delete queryParams[key];
      }
    });
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams
      });
  }

  getQueryParams(): Params {
    const queryParams: Params = {
      creationDateStart: this.searchForm.controls.creationDateStart.value ? this.convertNgbDateToOccString(this.searchForm.controls.creationDateStart.value) : null,
      creationDateEnd: this.searchForm.controls.creationDateEnd.value ? this.convertNgbDateToOccString(this.searchForm.controls.creationDateEnd.value) : null,
      sort: this.activeSort ? this.activeSort.field : null,
      sortDir: this.activeSort ? this.activeSort.descending : null,
      orderNumber: this.searchForm.controls.orderNumber.value,
      currentPage: this.currentPage,
      pageSize: this.pageSize,
      customer: this.searchForm.controls.customer.value,
      deliveryChannel: this.searchForm.controls.deliveryChannel.value,
      status: this.searchForm.controls.status.value,
      assignee: this.searchForm.controls.assignee.value,
      country: this.searchForm.controls.country.value
    };

    // Remove null or empty values
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] == null || (Array.isArray(queryParams[key]) && queryParams[key].length === 0)) {
        delete queryParams[key];
      }
    });

    return queryParams;
  }

  reset(): void {
    this.searchForm.reset({
      creationDateStart: this.halfYearBack, // 6  month backwards
      creationDateEnd: new Date(),
      size: [DEFAULT_PAGE_SIZE]
    });
    this.search();
  }

  gotoClaimRequest() {
    this.router.navigate(['/claim-request']);
  }

  getClaimRequest(): SsabClaimRequest {
    const creationDate = this.searchForm.controls.creationDateStart.value == null && this.searchForm.controls.creationDateEnd.value == null ? null : {
      from: this.convertNgbDateToOccString(this.searchForm.controls.creationDateStart.value),
      to: this.convertNgbDateToOccString(this.searchForm.controls.creationDateEnd.value)
    } as DateRange;

    const ssabClaimRequest = {
      dateRange: creationDate,
      sort: this.activeSort,
      orderId: this.searchForm.controls.orderNumber.value,
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      customerId: this.searchForm.controls.customer.value,
      deliveryChannel: this.searchForm.controls.deliveryChannel.value,
      claimStatus: this.searchForm.controls.status.value,
      assignee: this.searchForm.controls.assignee.value,
      country: this.searchForm.controls.country.value
    } as SsabClaimRequest;
    return ssabClaimRequest;
  }

  initializeForm(): void {
    this.searchForm = this.fb.group(
      {
        orderNumber: null,
        status: null,
        assignee: null,
        country: null,
        deliveryChannel: null,
        customer: null,
        creationDateStart: [this.halfYearBack], // 6  months backwards
        creationDateEnd: [new Date()],
        size: [DEFAULT_PAGE_SIZE]
      }
    );
  }

  pageChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.search();
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.search();
  }

  toggleDetails(id: string): void {
    this.detailsOpen = !this.detailsOpen;
  }

  convertNgbDateToOccString(d: any): string {
    if (d) {
      if (d.year && d.month && d.day) {
        return DateUtils.convertDateToOccString(this.language, this.ngbDateParserFormatter.toDate(d));
      }
      return DateUtils.convertDateToOccString(this.language, d);
    }
    return null;
  }

  getTableHeadType(user: SsabUser): TablesTypes {
    return user.internalUser ? TablesTypes.Claims_Internal : TablesTypes.Claims_External;
  }
}

