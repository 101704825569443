import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Optional, Output, SimpleChanges} from '@angular/core';
import {CmsComponentData, CurrentProductService, LAUNCH_CALLER, LaunchDialogService, ProductListItemContext} from '@spartacus/storefront';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {ButtonAction, SsabProduct, SsabStock, SsabUnitOfMeasure, StepperUpdateData} from '../../../model/product.model';
import {SsabOrderEntry} from '../../../model/cart.model';
import {SsabContactSalesProductListService} from '../../../service/contactsales/ssab-contactsales.service';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {Observable, Subscription} from 'rxjs';
import {SsabUser} from '../../../model/user.model';
import {SsabConsignmentListComponent} from '../minicart/consignment-list/ssab-consignment-list.component';
import {AddToCartComponent} from '@spartacus/cart/base/components/add-to-cart';
import {CmsAddToCartComponent, EventService} from '@spartacus/core';
import {SsabProductAvailabilityModalData} from '../../subscriptions/ssab-product-availability-modal/ssab-product-availability-modal-layout.config';
import {SsabConsignmentListDialogData} from '../minicart/consignment-list/ssab-consignment-list-layout.config';


@Component({
  selector: 'ssab-cx-add-to-cart',
  templateUrl: './ssab-add-to-cart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabAddToCartComponent extends AddToCartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() selectedUnit: string;
  @Output() update = new EventEmitter<StepperUpdateData>();
  @Input() isPLPView = false;
  @Input() isReorder = false;
  @Input() isFavoriteCarousel = false;
  @Input() reorderTextKey;
  @Input() minQuantity = 1;
  @Input() initialQuantity: number = 1;
  @Input() stockLevel = 0;
  @Input() currentStock: SsabStock;
  @Input() isSplitBundle: boolean = false;
  user$: Observable<SsabUser>;
  subscriptions: Subscription = new Subscription();
  protected modal: SsabConsignmentListComponent;

  constructor(
    protected currentProductService: CurrentProductService,
    protected cdr: ChangeDetectorRef,
    protected activeCartService: SsabActiveCartService,
    protected contactSalesProductListService: SsabContactSalesProductListService,
    protected gtmService: SsabGtmService,
    protected userService: SsabUserService,
    protected component: CmsComponentData<CmsAddToCartComponent>,
    protected eventService: EventService,
    protected launchDialogService: LaunchDialogService,
    @Optional() protected productListItemContext?: ProductListItemContext
  ) {
    super(currentProductService, cdr, activeCartService, component, eventService, productListItemContext);
    this.user$ = this.userService.get();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (!this.isReorder) {
      this.setMinMax();
      this.setSplitBundle(this.isSplitBundle);
    }
  }

  getSsabProduct(): SsabProduct {
    return this.product as SsabProduct;
  }

  showAddtoCart(): boolean {
    return this.getSsabProduct().buttonAction == ButtonAction.addToCart;
  }

  showConsignmentStock(): boolean {
    return this.getSsabProduct().buttonAction == ButtonAction.consignmentStock;
  }

  showContactSales(): boolean {
    return this.getSsabProduct().buttonAction == ButtonAction.contactSales;
  }

  showNotifyMe(): boolean {
    return this.getSsabProduct().buttonAction == ButtonAction.notifyMe;
  }

  showQuantityAndUnits(): boolean {
    return this.showAddtoCart() || this.showContactSales() || (this.reorderTextKey !== null && this.reorderTextKey !== undefined);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initialQuantity = undefined;
    if (changes.currentStock?.currentValue?.canSplitBundle === false) {
      this.setSplitBundle(false);
    }
    this.setMinMax();
  }

  setMinMax(): void {
    this.minQuantity = this.isSplitBundle ? this.currentStock.bundleSize ?? 1 : this.currentStock.minimumAvailableQuantity ?? 1;
    if (this.showContactSales()) {
      this.maxQuantity = null; // null = unlimited
      if (this.minQuantity === 0) {
        this.minQuantity = 1; // for viewOnly stocks stockLevel is not important, so always positive to enable contactSales button
      }
    } else {
      this.maxQuantity = this.stockLevel;
      if (this.stockLevel < this.minQuantity || this.getSsabProduct().stock.stockLevel === 0) {
        this.minQuantity = 0;
        this.maxQuantity = 0;
      }
    }
    if (this.initialQuantity === undefined || this.initialQuantity < this.minQuantity || (this.maxQuantity && this.initialQuantity > this.maxQuantity)) {
      this.initialQuantity = this.minQuantity;
    }
  }

  addToCart(): void {
    if (!this.productCode || this.initialQuantity <= 0) {
      return;
    }
    this.activeCartService.addSsabEntry({
      product: {code: this.getSsabProduct().code, stock: {warehouseCode: this.currentStock.warehouseCode}},
      quantity: this.initialQuantity,
      unit: {code: this.selectedUnit} as SsabUnitOfMeasure,
      splitBundle: this.isSplitBundle
    } as SsabOrderEntry);
  }

  setSplitBundle(isSplitBundle: boolean): void {
    this.isSplitBundle = isSplitBundle;
    this.initialQuantity = undefined;
    this.setMinMax();
  }

  openConsignmentList(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CONSIGNMENT_LIST, undefined, {
      product: this.getSsabProduct(),
      batchesID: []
    } as SsabConsignmentListDialogData);
  }

  quantityChange(updatedQuantity: StepperUpdateData): void {
    if (updatedQuantity.qty) {
      this.update.emit(updatedQuantity);
      this.initialQuantity = updatedQuantity.qty;
      this.cdr.detectChanges();
    }
  }

  unitChange(updatedUnit: SsabUnitOfMeasure): void {
    this.selectedUnit = updatedUnit.code;
    this.update.emit({unit: updatedUnit.code} as StepperUpdateData);
  }

  subscribeToAvailability(): void {
    this.launchDialogService.closeDialog(null);
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.PRODUCT_AVAILABILITY_MODAL, undefined, {
      productCode: this.productCode,
      isSubscriptionSuccess: false,
      isSubscriptionFail: false,
      user: this.user$
    } as SsabProductAvailabilityModalData);
  }

  contactSales(): void {
    if (!this.productCode || this.initialQuantity <= 0) {
      return;
    }
    this.contactSalesProductListService.addSsabEntry({
      product: {code: this.getSsabProduct().code, stock: {warehouseCode: this.currentStock.warehouseCode}},
      quantity: this.initialQuantity,
      unit: {code: this.selectedUnit} as SsabUnitOfMeasure
    } as SsabOrderEntry);
  }

  isBundleSplitShown(): boolean {
    return (this.showAddtoCart() && this.getSsabProduct().stock.canSplitBundle);
  }

  isInValid(): boolean {
    return this.getSsabProduct().stock.maxmimumAvailableQuantity && this.getSsabProduct().stock.maxmimumAvailableQuantity < this.initialQuantity;
  }
}
