import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrderHistoryConnector} from '@spartacus/order/core';
import {OrderTrackingTypes, SsabOrderFeedbackForm, SsabOrderHistory, SsabOrderHistoryRequest} from '../../model/order.model';
import {SsabOccUserOrderAdapter} from './ssab-occ-user-order.adapter';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SsabUserOrderConnector extends OrderHistoryConnector {
  constructor(protected adapter: SsabOccUserOrderAdapter) {
    super(adapter);
  }

  public getOrderHistory(userId: string, selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<SsabOrderHistory> {
    return this.adapter.loadOrderHistory(userId, selectedView, params);
  }

  public getOrderHistoryDownload(userId: string, selectedView: OrderTrackingTypes, params: SsabOrderHistoryRequest): Observable<any> {
    return this.adapter.downloadOrderHistory(userId, selectedView, params);
  }

  sendOrderFeedback(code: string, orderSource: string, mill: string, mdmCompanyId: string, value: SsabOrderFeedbackForm): void {
    this.adapter.sendOrderFeedback('current', code, orderSource, mill, mdmCompanyId, value)
      .pipe(take(1))
      .subscribe();
  }
}
