import {Inject, Injectable, OnDestroy} from '@angular/core';
import {SsabCdcConfig} from './ssab-cdc-config';
import {SsabAuthService} from "../user-auth/ssab-auth.service";
import {CdcAuthentication} from "../../model/user.model";
import {BaseSiteService, LanguageService, RoutingService, WindowRef} from "@spartacus/core";
import {BehaviorSubject, Observable, Subscription} from "rxjs";
import {take} from "rxjs/operators";
import {DOCUMENT} from "@angular/common";

declare const gigya: any;

@Injectable()
export class SsabCdcService implements OnDestroy {

  subscriptions = new Subscription();

  constructor(private cdcConfig: SsabCdcConfig,
              private authService: SsabAuthService,
              private languageService: LanguageService,
              private baseSiteService: BaseSiteService,
              @Inject(DOCUMENT) private document: Document,
              protected routingService: RoutingService) {
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }

  public openMyProfileUpdate(): void {
    this.subscriptions.add(
      this.languageService.getActive().subscribe(lang => {
        this.baseSiteService.getActive().subscribe(site => {
          // render profile update ScreenSet
          if (site === 'gcp') {
            gigya.accounts.showScreenSet(
              {
                screenSet: this.cdcConfig.cdc.screenSets.gcpProfileUpdate.screenSet,
                startScreen: this.cdcConfig.cdc.screenSets.gcpProfileUpdate.startScreen,
                containerID: this.cdcConfig.cdc.screenSets.gcpProfileUpdate.containerId,
                lang: lang
              }
            );
          } else {
            gigya.accounts.showScreenSet(
              {
                screenSet: this.cdcConfig.cdc.screenSets.profileUpdate.screenSet,
                startScreen: this.cdcConfig.cdc.screenSets.profileUpdate.startScreen,
                containerID: this.cdcConfig.cdc.screenSets.profileUpdate.containerId,
                lang: lang
              }
            );
          }
        })
      })
    );
  }

  public openPasswordResetForm(): void {
//    console.log("opening password reset form");
    this.subscriptions.add(
      this.languageService.getActive().subscribe(lang => {

        this.authService.setCdcLoginInProgress(false);

        /**
         Workaround for CDC login issue in incognito mode.

         Third-party cookies and data are blocked with incognito mode. When enabled, this privacy feature
         deactivates all cookies and storage within the iframe, which is required to securely authenticate the user.
         */
        this.registerCustomCdcLoginSuccessHandler();


        // register login event handler
        gigya.accounts.addEventHandlers({
          onLogin: (data) => this.loginWithCDCData(data)
        })

        // render password reset ScreenSet
        gigya.accounts.showScreenSet(
          {
            screenSet: this.cdcConfig.cdc.screenSets.passwordReset.screenSet,
            startScreen: this.cdcConfig.cdc.screenSets.passwordReset.startScreen,
            containerID: this.cdcConfig.cdc.screenSets.passwordReset.containerId,
            lang: lang
          }
        );
      })
    );
  }

  cdcRendered = new BehaviorSubject<boolean>(false);

  public openLoginForm(): void {
    if (!this.languageService.isInitialized()) {
      // fixes issue with endless spinner on login page
      console.log("languageService not initialized - set english as active language");
      this.languageService.setActive("en");
    }

    this.subscriptions.add(
      this.languageService.getActive()
        .subscribe(lang => {
          this.authService.setCdcLoginInProgress(false);

          /**
           Workaround for CDC login issue in incognito mode.

           Third-party cookies and data are blocked with incognito mode. When enabled, this privacy feature
           deactivates all cookies and storage within the iframe, which is required to securely authenticate the user.
           */
          this.registerCustomCdcLoginSuccessHandler();

          // register login event handler

          gigya.accounts.addEventHandlers({
            onLogin: (data) => {
              // no need for duplicate redirects
              //this.loginWithCDCData(data);
              this.cdcRendered.next(false);
            }
          });

          // render login ScreenSet
          gigya.accounts.showScreenSet(
            {
              screenSet: this.cdcConfig.cdc.screenSets.login.screenSet,
              startScreen: this.cdcConfig.cdc.screenSets.login.startScreen,
              containerID: this.cdcConfig.cdc.screenSets.login.containerId,
              lang: lang
            }
          );
          const cdcInterval = setInterval(() => {
            if (this.document.getElementsByName('username').length > 0) {
              this.cdcRendered.next(true);
              clearInterval(cdcInterval);
            }
          }, 200) as any;
        })
    );
  }

  private registerCustomCdcLoginSuccessHandler() {
    (function (cdcService: SsabCdcService) {
      var _origOpen = XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function () {
        this.addEventListener('load', function () {
          if (this.responseURL.endsWith('/accounts.login') || this.responseURL.indexOf('accounts.finalizeRegistration') >= 0) {
            const data = JSON.parse(this.response);
            if (data.statusCode === 200 && !!data.UID) {
              cdcService.loginWithCDCData(data);
            }
          }
        });
        _origOpen.apply(this, arguments);
      };
    })(this);
  }

  protected loginWithCDCData(data: any): void {
    if (this.authService.isCdcLoginInProgress()) {
      return;
    }

    this.authService.setCdcLoginInProgress(true);

    const auth: CdcAuthentication = {
      uid: this.getUID(data),
      uidSignature: this.getUidSignature(data),
      signatureTimestamp: this.getSignatureTimestamp(data)
    };

    this.authService.commerceLoginForCdcAuthentication(auth);
    this.cdcRendered.next(false);
  }

  private getUID(data: any): string {
    return data.UID;
  }

  private getUidSignature(data: any): string {
    return data.UIDSignature;
  }

  private getSignatureTimestamp(data: any): string {
    return data.signatureTimestamp;
  }

  ssoLogin(language: string): void {
    this.authService.ssoLogin(language);
  }

}
