<h2 class="text-white cx-page-header-title">
  {{ 'ssab.documents.search.documentsSearch' | cxTranslate }}
</h2>

<div class="cx-documents-tab" *ngIf="hiddenDocumentTypes$ | async as hiddenDocumentTypes">
  <h3 class="d-inline-block" *ngIf="!hiddenDocumentTypes.includes(documentTypes.Certificate)"
      [ngClass]="selectedDocument == documentTypes.Certificate ? 'selected': ''"
      (click)="changeDocumentType(documentTypes.Certificate)">{{ 'ssab.documents.search.certificates.title' | cxTranslate }}</h3>
  <h3 class=" d-inline-block" *ngIf="!hiddenDocumentTypes.includes(documentTypes.Confirmation)"
      [ngClass]="selectedDocument == documentTypes.Confirmation ? 'selected': ''"
      (click)="changeDocumentType(documentTypes.Confirmation)">{{ 'ssab.documents.search.orderConfirmation.title' | cxTranslate }}</h3>
  <h3 class="d-inline-block" *ngIf="!hiddenDocumentTypes.includes(documentTypes.Invoice)"
      [ngClass]="selectedDocument == documentTypes.Invoice ? 'selected': ''"
      (click)="changeDocumentType(documentTypes.Invoice)">{{ 'ssab.documents.search.invoices.title' | cxTranslate }}</h3>
  <h3 class="d-inline-block" *ngIf="!hiddenDocumentTypes.includes(documentTypes.Transport)"
      [ngClass]="selectedDocument == documentTypes.Transport ? 'selected': ''"
      (click)="changeDocumentType(documentTypes.Transport)">{{ 'ssab.documents.search.transportDocuments.title' | cxTranslate }}</h3>
</div>

<!-- Template for days -->
<ng-template #customDay let-date let-currentMonth="currentMonth" let-today="today" let-disabled="disabled"
             let-selected="selected">
  <ssab-datepicker-day
    [today]="today"
    [selected]="selected"
    [hidden]="date.month !== currentMonth"
    [hasContent]="true"
    [date]="date"
  >
  </ssab-datepicker-day>
</ng-template>

<!--Popover templates  -->
<ng-template #popDocumentsSearchInfo>
  <div class="text-default row vertical-align-item">
    <div class="col-1 text-left"><span class="ssab-icon icon-system-info icon-inline"></span></div>
    <span
      class="text-opacity col-11">{{ 'ssab.documents.search.infoSearch.' + (selectedDocument | lowercase) | cxTranslate }}</span>
  </div>
</ng-template>

<ng-container *ngIf="searchDocumentsForm">
  <!-- SEARCH CRITERIA -->
  <form [formGroup]="searchDocumentsForm" (ngSubmit)="search()" class="cx-documents-search pb-5 pb-md-0">
    <ng-container *ngIf="user$ | async as user">
      <!-- Tablet and mobile search criteria-->
      <div class="d-md-inline-block d-lg-none cx-documents-instructions col-12" (click)="showFilterOptions($event)">
        <span>{{'common.swipe.instructions' | cxTranslate}}</span>
        <span class="float-right">
        {{ 'productList.filterBy.label' | cxTranslate }}
          <i class="ssab-icon icon-system-filter icon-inline ml-3"></i>
    </span>
      </div>
      <div class="d-none d-lg-inline-flex inner row align-items-end w-100 no-margin">
        <div class="col-12 p-0 content">
          <div class="row align-items-end w-100 no-margin">
            <h3 class="col-12 pb-5 mb-0 d-lg-none d-md-block">
              {{'ssab.search.criteria' | cxTranslate}}
              <i class="ssab-icon icon-inline icon-navigation-close float-right" (click)="hideFilterOptions($event)"></i>
            </h3>
          </div>
          <ng-container *ngIf="user.internalUser">
            <div class="row align-items-end w-100 no-margin">
              <ng-container *ngTemplateOutlet="searchFields"></ng-container>
              <ng-container *ngTemplateOutlet="searchFieldsDeliveryDates"></ng-container>
              <ng-container *ngTemplateOutlet="searchFieldsButton;context:{ css:'d-none d-lg-block'}"></ng-container>
            </div>
            <div class="row align-items-end w-100 no-margin">
              <ng-container *ngTemplateOutlet="searchFieldsDropdowns"></ng-container>
              <ng-container *ngTemplateOutlet="searchFieldsButton;context:{ css:'d-block d-lg-none'}"></ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="!user.internalUser">
            <div class="row align-items-end w-100 no-margin">
              <ng-container *ngTemplateOutlet="searchFields"></ng-container>
              <ng-container *ngTemplateOutlet="searchFieldsDropdowns"></ng-container>
              <ng-container *ngTemplateOutlet="searchFieldsDeliveryDates"></ng-container>
              <ng-container *ngTemplateOutlet="searchFieldsButton;context:{ css:''}"></ng-container>
            </div>
          </ng-container>
        </div>
      </div>
      <ng-template #searchFields>
        <div #documentSearch class="form-group col-12 cx-documents-search-option pl-lg-0"
             [ngClass]="(allowedCustomers$ | async) ? user.internalUser? 'col-lg-6 is-internal-user' : 'col-lg-2' : 'col-lg-4'">
          <label class="text-lg">
            <span>{{'ssab.documents.search.keywords.label' | cxTranslate}}</span>
            <span class="ssab-icon icon-inline icon-system-info ml-2 icon-label" role="button"
                  placement="right"
                  popoverClass="popover-sm popover-info" [ngbPopover]="popDocumentsSearchInfo"
                  triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
          </label>
          <ssab-cx-input class="pr-0"
                         [typeInput]="'text'"
                         [idName]="'keywords'"
                         [nameForm]="'keywords'"
                         [parentForm]="searchDocumentsForm"
                         [isNotValidFunction]="isInvalid('keywords')"
                         [labelPlaceholder]="'ssab.documents.search.keywords.placeholder.' + (selectedDocument | lowercase)">

          </ssab-cx-input>
        </div>
      </ng-template>
      <ng-template #searchFieldsDropdowns>
        <ng-container *ngIf="user.internalUser">
          <div #divCustomers class="form-group col-lg-2 col-12 cx-documents-search-option cx-documents-search-select pl-lg-0"
               *ngIf="(allowedCustomers$ | async) !=null">
            <h4 class="option-header">
              <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3" (click)="hideSearchOptions(divCustomers)"></i>
              {{'ssab.documents.search.customer' | cxTranslate}}
              <i class="float-right ssab-icon icon-navigation-close icon-inline" (click)="hideSearchOptions(divCustomers)"></i>
            </h4>
            <label class="text-lg">{{'ssab.documents.search.customer' | cxTranslate}}</label>
            <ng-select #customers
                       [items]="customerIds$ | async"
                       [searchable]="true"
                       [closeOnSelect]="true"
                       [clearable]="true"
                       [clearOnBackspace]="false"
                       dropdownPosition="bottom"
                       bindLabel="name"
                       bindValue="uid"
                       id="customer"
                       formControlName="customers"
                       placeholder="{{ 'ssab.documents.search.allCustomers' | cxTranslate }}"
                       [typeahead]="customerIdsInput$"
                       [isOpen]="isFilterOpen(divCustomers)"
                       (blur)="hideSearchOptionsDesktop(divCustomers)"
                       (close)="hideSearchOptionsDesktop(divCustomers)"
                       (change)="onCustomerChange(divCustomers, true)"
                       (click)="toggleFilter(divCustomers)"
                       (clear)="clearCustomers()"
            >
              <ng-template ng-option-tmp let-item="item">
                <span title="{{item.name}}">{{item.name}}</span>
              </ng-template>
            </ng-select>
          </div>
        </ng-container>

        <div #divShipToLocation class="form-group col-lg-2 col-12 cx-documents-search-option cx-documents-search-select pl-lg-0">
          <h4 class="option-header">
            <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3" (click)="hideSearchOptions(divShipToLocation)"></i>
            {{'ssab.documents.search.shipToLocation' | cxTranslate}}
            <i class="float-right ssab-icon icon-navigation-close icon-inline" (click)="hideSearchOptions(divShipToLocation)"></i>
          </h4>
          <label class="text-lg">{{'ssab.documents.search.shipToLocation' | cxTranslate}}</label>
          <ng-select #shipToLocation
                     [items]="shipToLocations$ | async"
                     [searchable]="true"
                     [closeOnSelect]="true"
                     [clearable]="true"
                     [clearOnBackspace]="false"
                     dropdownPosition="bottom"
                     bindLabel="name"
                     bindValue="uid"
                     id="shipToLocation"
                     formControlName="shipToLocation"
                     placeholder="{{ 'ssab.documents.search.allShipToLocation' | cxTranslate }}"
                     [typeahead]="shipToLocationsInput$"
                     [isOpen]="isFilterOpen(divShipToLocation)"
                     (blur)="hideSearchOptionsDesktop(divShipToLocation)"
                     (close)="hideSearchOptionsDesktop(divShipToLocation)"
                     (click)="toggleFilter(divShipToLocation)"
                     (clear)="clearShipTo()"
          >
            <ng-template ng-option-tmp let-item="item">
              <span title="{{item.name}}">{{item.name}}</span>
            </ng-template>
          </ng-select>
        </div>
      </ng-template>
      <ng-template #searchFieldsDeliveryDates>
        <div #divCreationDateStart class="form-group col-lg-2 col-12 cx-documents-search-option pl-lg-0">
          <label class="text-lg d-none d-lg-block">{{'ssab.documents.search.' + selectedDocument + '.dateFrom' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'creationDateStart'"
                                 [idName]="'creationDateStart'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchDocumentsForm"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.documents.search.'+selectedDocument+'.dateFrom'"
                                 [selectedDate]="convertDate(searchDocumentsForm.get('creationDateStart').value)"
                                 (click)="toggleFilter(divCreationDateStart)"
                                 (onChangeEvent)="hideSearchOptions(divCreationDateStart)"
                                 [navigation]="'select'"
          >
          </ssab-datepicker-input>
        </div>
        <div #divCreationDateEnd class="form-group col-lg-2 col-12 cx-documents-search-option pl-lg-0">
          <label class="text-lg d-none d-lg-block">{{'ssab.documents.search.' + selectedDocument + '.dateTo' | cxTranslate}}</label>
          <ssab-datepicker-input class="calendar-dark"
                                 [nameForm]="'creationDateEnd'"
                                 [idName]="'creationDateEnd'"
                                 [typeInput]="'text'"
                                 [parentForm]="searchDocumentsForm"
                                 [customDayTemplate]="customDay"
                                 [labelPlaceholder]="'ssab.documents.search.'+selectedDocument+'.dateTo'"
                                 [selectedDate]="convertDate(searchDocumentsForm.get('creationDateEnd').value)"
                                 (click)="toggleFilter(divCreationDateEnd)"
                                 (onChangeEvent)="hideSearchOptions(divCreationDateEnd)"
                                 [navigation]="'select'"
          >
          </ssab-datepicker-input>
        </div>
      </ng-template>
      <ng-template #searchFieldsButton let-css="css">
        <div class="form-group col-lg-2 col-12 cx-documents-search-option cx-documents-search-select pr-lg-0 pl-lg-0 {{css}}">
          <div class="row justify-content-end no-margin">
            <button
              class="btn btn-link btn-reset btn-default-height col-12 col-md-4 d-block d-lg-none text-uppercase text-bold order-last order-md-first">
              {{'ssab.search.reset' | cxTranslate}}
            </button>
            <button
              class="btn btn-primary btn-default-height btn-block col-12 col-md-6 col-lg-12 "
              type="submit"
              (click)="hideFilterOptions($event)">
              {{ 'ssab.search.resultTitle' | cxTranslate }}
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </form>
</ng-container>

<ng-container *ngIf="certificates$ | async as certificateDocumentList; else noDocuments">
  <ng-container *ngIf="invoices$ | async as invoiceList; else noDocuments">
    <ng-container *ngIf="ordersConfirmation$ | async as ordersConfirmationList; else noDocuments">
      <ng-container *ngIf="transports$ | async as transportList; else noDocuments">
        <ssab-cx-documents-result
          [certificates]="certificateDocumentList.documents"
          [invoices]="invoiceList.documents"
          [ordersConfirmations]="ordersConfirmationList.documents"
          [transports]="transportList.documents"
          [pagination]="transportList.documents?transportList.pagination:
          ordersConfirmationList.documents?ordersConfirmationList.pagination:
          invoiceList.documents?invoiceList.pagination:
          certificateDocumentList.documents?certificateDocumentList.pagination:null"
          [activeSort]="activeSort"
          [selectedDocument]="selectedDocument"
          (onSearch)="search($event)"
          (onSort)="sortChange($event)"
          (downloadAll)="downloadAllDocuments()"
        ></ssab-cx-documents-result>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>


<ng-template #noDocuments>
  <div class="entries-data empty-data-div">
  </div>
</ng-template>
