import {SsabDocumentIdentifier, SsabDocumentList, SsabPaginationModel} from "./document.model";
import {UserGroup} from "@spartacus/organization/administration/core";
import {DateRange, ValueRange} from "./misc.model";
import {Sort} from "./order.model";
import {B2BUnitOption} from "./user.model";
import {Country, User} from "@spartacus/core";

export enum ClaimDetailTypes {
  Details = 'Details',
  Documents = 'Documents',
  Messages = 'Messages',
}

export enum ClaimAssigneeTypes {
  Fixed = 'Fixed',
  Temporary = 'Temporary',
}

export enum ClaimStatusTypes {
  inProcess = 'inProcess',
  evaluated = 'evaluated',
  submitted = 'submitted',
  completed = 'completed',
  rejected = 'rejected',
}

export interface SsabClaimRequest {
  dateRange?: DateRange;
  sort?: Sort;
  orderId?: string;
  from?: number;
  pageNumber?: number;
  pageSize?: number;
  customerId?: string;
  deliveryChannel?: string;
  claimStatus?: string[];
  assignee?: string;
}

export interface SsabClaimStatusRequest {
  complaintNumber?: string;
  messageToCustomer?: string;
  status?: string;
  linkToClaim?: boolean;
}

export interface SsabClaimAssigneeRequest {
  firstName?: string;
  lastName?: string;
  temporary?: boolean;
  email?: string;
  uid?: string;
  message?: string;
  assignmentDateStart?: string;
  assignmentDateEnd?: string;
}

export interface SsabClaimCreateRequest {
  orderNumber?: string;
  partialClaim?: boolean;
  orderLines?: string[];
  contactEmail?: string;
  technicalContactEmail?: string;
  customerName?: string;
  claimSubject?: string;
  defectType?: string;
  defectedArea?: string;
  claimReason?: string;
  urgent?: boolean;
  urgencyReason?: string;
  requestedCompensation?: string;
  customerReferenceId?: string;
  purchaseOrderNumber?: string;
  draft?: boolean;
  claimId?: string;
  customer?: string;
  claimEntries?: SsabClaimEntry[];
}

export interface SsabClaim {
  code?: string;
  orderNumber?: string;
  purchaseOrderNumber?: string;
  customer?: B2BUnitOption;
  claimSubject?: string;
  customerName?: string;
  claimCreationDate?: string;
  assignee?: User;
  claimStatus?: string;
  claimReason?: string;
  defectType?: string;
  partialClaim?: boolean;
  defectedArea?: string;
  customerReferenceId?: string;
  draft?: boolean;
  urgent?: boolean;
  urgencyReason?: string;
  requestedCompensation?: string;
  allowedOrders: string[];
  claimContactEmails?: string[];
  technicalContactEmail?: string;
  salesContactEmail?: string;
  allowedCustomers?: UserGroup[];
  claimEntries?: SsabClaimEntry[];
  draftEntries?: SsabClaimEntry[];
  allowedUnits?: string[];
  allowedDefectedTypes?: string[];
  claimDeliveryAddress?: string;
  deliveryChannel?: string;
  ssab8DDocumentLinked?: boolean
  claimAttachments?: SsabDocumentIdentifier[];
  claimId?: string;
  claimMessages: SsabClaimMessage[];
  unreadMessages?: number;
  possibleAssignees?: UserGroup[];
  tempAssigneeFrom?: string;
  tempAssigneeTo?: string;
}

export interface SsabClaimMessage {
  messageDate?: string;
  message?: string;
  messageOwner?: string;
  current?: boolean;
  messageRead?: boolean;
}

export interface SsabClaimEntry {
  orderLine?: string;
  orderLineName?: string;
  materials?: SsabClaimMaterial[];
}

export interface SsabClaimMaterial {
  orderLine?: string;
  custom?: boolean;
  materialId?: string;
  quantity?: string;
  unit?: string;
}

export interface SsabClaimList {
  claims?: SsabClaim[];
  pagination?: SsabPaginationModel;
  allowedCustomers?: UserGroup[];
  allowedAssignees?: UserGroup[];
  allowedCountries?: Country[];
  allowedStatuses?: string[];
  allowedDeliveryChannels?: string[];
}

export interface SsabClaimDocument extends SsabDocumentIdentifier {
  complaintNumber?: string;
}

export interface SsabClaimDocumentList extends SsabDocumentList {
  documents?: SsabClaimDocument[];
}
