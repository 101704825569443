<!--Popover templates  -->
<ng-template #popContentCoilIDInfo>
  <div class="text-default">
    {{ 'ssab.coil.comparison.search.coilId.tooltip' | cxTranslate }}
  </div>
</ng-template>
<ng-template #popContentSearchTermInfo>
  <div class="text-default">
    {{ 'ssab.coil.comparison.search.searchTerm.tooltip' | cxTranslate }}
  </div>
</ng-template>

<div class="cx-coils-search">
  <div id="cx-coils-header">
    <h2 class="text-white cx-page-header-title">
      {{ 'ssab.coil.comparison.page.title' | cxTranslate }}
    </h2>
    <p class="text-white cx-page-header-paragraph">{{ 'ssab.coil.comparison.page.description' | cxTranslate }}</p>
    <!-- Tabs -->
    <div class="cx-coils-tab row no-gutters w-100">
      <div class="col-12 col-lg-4 pb-4 pb-lg-0">
        <h3 class="pr-4 d-inline">{{ 'ssab.coil.comparison.tab' | cxTranslate }}</h3>
      </div>
      <div class=" col-12 col-lg-8 row no-gutters">
        <div class="col-auto col-md-4 col-lg-4 d-flex text-right order-3 order-md-2 text-bold col-no-padding ml-auto d-lg-none d-md-block">
          <span (click)="showFilterOptions($event)">
            {{ 'ssab.order.history.filter.true' | cxTranslate }}
            <i class="ssab-icon icon-system-filter icon-inline ml-3 icon-cursor icon-white"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="searchResult$ |async as searchResult">
    <form [formGroup]="form" (ngSubmit)="search(true)" class="cx-coils-form d-none d-lg-block">
      <div class="cx-search-form row">
        <h3 class="col-12 pb-5 mb-3 d-lg-none d-md-block">{{ 'ssab.search.criteria' | cxTranslate }}
          <i class="ssab-icon icon-navigation-close icon-cursor float-right" (click)="hideFilterOptions($event)"></i>
        </h3>

        <div class="form-group cx-coils-search-option col-lg-6 col-12">
          <label class="text-lg">
            <span>{{ 'ssab.coil.comparison.search.coilId.label' | cxTranslate }}</span>
            <span class="ssab-icon icon-inline icon-system-info d-none d-sm-inline-block ml-2 icon-white" role="button"
                  placement="right"
                  popoverClass="popover-fix-content popover-info" [ngbPopover]="popContentCoilIDInfo"
                  triggers="manual" (click)="popover1.open()" #popover1="ngbPopover"></span>
          </label>
          <ssab-cx-input class="pr-0 input-no-border"
                         [typeInput]="'text'"
                         [idName]="'coilId'"
                         [nameForm]="'coilId'"
                         [parentForm]="form"
                         [labelPlaceholder]="'ssab.coil.comparison.search.coilId.placeholder'"
                         (afterEnter)="search(true)">
          </ssab-cx-input>
        </div>

        <div class="form-group cx-coils-search-option col-lg-3 col-12">
          <label class="text-lg">
            <span>{{ 'ssab.coil.comparison.search.searchTerm.label' | cxTranslate }}</span>
            <span class="ssab-icon icon-inline icon-system-info d-none d-sm-inline-block ml-2 icon-white" role="button"
                  placement="top"
                  popoverClass="popover-fix-content popover-info" [ngbPopover]="popContentSearchTermInfo"
                  triggers="manual" (click)="popover2.open()" #popover2="ngbPopover"></span>
          </label>
          <ssab-cx-input class="pr-0 input-no-border"
                         [typeInput]="'text'"
                         [idName]="'searchTerm'"
                         [nameForm]="'searchTerm'"
                         [parentForm]="form"
                         [labelPlaceholder]="'ssab.coil.comparison.search.searchTerm.placeholder'"
                         (afterEnter)="search(true)">
          </ssab-cx-input>
        </div>
        <ng-container *ngIf="facets$|async as facets">
          <ng-container *ngFor="let facet of facets">
            <div class="form-group cx-coils-search-option col-lg-3 col-12" [ngClass]="!facet.range ? 'cx-coils-search-select' : ''">
              <label class="text-lg">
                <span>{{ 'ssab.coil.comparison.search.' + facet.code + '.label' | cxTranslate }}</span>
              </label>
              <div class="value facet-range vertical-align-item" *ngIf="facet.range">
                <ssab-cx-input-range
                  [inputValueFrom]="facet.rangeMin"
                  [inputValueTo]="facet.rangeMax"
                  [inputValueStep]="0.01"
                  [sliderForm]="form"
                  [showUnique]="true"
                  [controlName]="'FACET_THICKNESS'"
                  class="w-100"
                ></ssab-cx-input-range>
              </div>

              <ng-select *ngIf="!facet.range"
                         [items]="facet.values"
                         [clearable]="true"
                         [formControlName]="facetPrefix+facet.code"
                         placeholder="{{ 'ssab.coil.comparison.search.'+facet.code+'.placeholder' | cxTranslate }}"
                         dropdownPosition="bottom"
                         (change)="updateFacets()"
                         bindLabel="name"
                         bindValue="code">
                <ng-template ng-label-tmp let-item="item">
                  <i *ngIf="item.query" class="ssab-icon icon-color-box icon-inline mr-2" style="background-color: rgb({{ item.query.url }})"></i>
                  <span [innerHTML]="item.name"></span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <i *ngIf="item.query" class="ssab-icon icon-color-box icon-inline mr-2" style="background-color: rgb({{ item.query.url }})"></i>
                  <span [innerHTML]="item.name"></span>
                </ng-template>
              </ng-select>

            </div>
          </ng-container>
        </ng-container>

        <!!--This is for making a break in the columns so buttons can be separated -->
        <div class="w-100 pb-3 d-none d-lg-block"></div>

        <div class="col-12 col-md-3 text-right form-group ml-md-auto d-md-block d-none">
          <div class="form-check mb-0 d-inline show-stock" *ngIf="searchResult.internalUser">
            <input class="form-check-input position-relative"
                   id="showStock"
                   [formControlName]="'showStocks'"
                   (change)="updateFacets()"
                   type="checkbox">
            &nbsp;
            <label class="form-check-label text-lg" [innerHtml]="'ssab.coil.comparison.search.showStock' | cxTranslate"
                   for="showStock"></label>
          </div>
        </div>

        <div class="col-12 col-md-3 text-center form-group d-md-none d-block">
          <div class="form-check mb-0 d-inline show-stock" *ngIf="searchResult.internalUser">
            <input class="form-check-input position-relative"
                   id="showStock-mobile"
                   [formControlName]="'showStocks'"
                   (change)="updateFacets()"
                   type="checkbox">
            &nbsp;
            <label class="form-check-label text-lg" [innerHtml]="'ssab.coil.comparison.search.showStock' | cxTranslate"
                   for="showStock-mobile"></label>
          </div>
        </div>

        <div class="col-12 col-md-1 text-right form-group d-md-block d-none">
          <button
            (click)="reset()"
            class="btn btn-link btn-default-height text-uppercase text-right p-0"
            type='button'>
            {{ 'ssab.search.reset' | cxTranslate }}
          </button>
        </div>

        <div class="col-12 col-md-3 form-group">
          <button
            class="btn btn-primary btn-default-height btn-block"
            type="submit">
            {{ 'ssab.search.resultTitle' | cxTranslate }}
          </button>
        </div>

        <div class="col-12 col-md-3 text-center form-group d-md-none d-block">
          <button
            (click)="reset()"
            class="btn btn-link btn-reset btn-default-height text-uppercase text-right p-0"
            type='button'>
            {{ 'ssab.search.reset' | cxTranslate }}
          </button>
        </div>
      </div>
    </form>
    <div class="cx-coils-body">
      <div class="table-responsive">
        <table class="table cx-coils-table">
          <thead table-head
                 (sortChange)="sort($event)"
                 [activeSort]="activeSort"
                 [label]="'ssab.coil.comparison.'"
                 [tableClass]="searchResult.showStocks ? 'stock-mode' : ''"
                 [tableHeadType]="getTableHeadType(searchResult)">
          </thead>
          <tbody>
          <ng-container *ngIf="compareResult$|async as compareResult">
            <ng-container *ngFor="let coil of searchResult.coils">
              <ng-template #tooltip>
                <span [innerHTML]="getCompareTooltipKey(coil,compareResult) | cxTranslate">
                </span>
              </ng-template>
              <tr>
                <td class="col-md-1 col-auto text-center">
                  <button class="btn btn-icon {{getCompareClass(compareResult,coil)}}"
                          (click)="toggleComparison(coil.coilId)"
                          [disabled]="!isCoilAllowedToBeAdded(coil,compareResult)"
                          [ngbPopover]="tooltip"
                          triggers="mouseenter:mouseleave"
                          container="body"
                          placement="right"
                          popoverClass="popover-fix-content popover-info ml-3"
                  >
                    <span class="ssab-icon icon-arrows-left-right"></span>
                  </button>
                </td>
                <td class="col-md-1 col-auto">
                  {{ coil.coilId }}
                </td>
                <td class="col-md-2 col-auto">
                  {{ coil.productName }}
                </td>
                <td class="col-md-2 col-auto">
                  <div class="text-space-nowrap">
                    <i *ngIf="coil.colorRgb" class="ssab-icon icon-color-box icon-inline mr-2" style="background-color: rgb({{ coil.colorRgb }})"></i>
                    <span>{{ coil.colorName }}</span>
                  </div>
                </td>
                <td class="col-md-2 col-auto">
                  {{ coil.dimensions }}
                </td>
                <td class="col-md-2 col-auto">
                  {{ coil.orderNumber }}
                </td>
                <td class="col-md-2 col-auto">
                  {{ coil.shiptToAddress }}
                </td>
                <td class="col-md-2 col-auto">
                  {{ convertUIDate(coil.productionTime)|cxDate: (getDateFormat()|async) }}
                </td>
                <ng-container *ngIf="searchResult.showStocks">
                  <td class="col-md-2 col-auto">
                    {{ coil.stock?.address }}
                  </td>
                  <td class="col-md-2 col-auto">
                    {{ coil.stock?.displayAmount }}
                  </td>
                </ng-container>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
      <ssab-cx-pagination-bottom
        [selectedPageSize]="pageSize"
        [pagination]="searchResult?.pagination"
        [skipLocationChange]="true"
        (viewPageEvent)="pageChange($event)"
        (pageSizeEvent)="setPageSize($event)"
      ></ssab-cx-pagination-bottom>
    </div>
  </ng-container>
</div>


