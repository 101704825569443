<div class="ssab-modal-xl ssab-modal">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" *ngIf="compareResult$|async as compareResult">
      <div class="modal-header no-print">
        <h3 class="modal-title d-inline">
          {{ 'ssab.coil.comparison.view.title'|cxTranslate }}
        </h3>
        <i class="float-right icon-md ssab-icon icon-navigation-close icon-inline" (click)="close()"></i>
      </div>
      <div class="modal-body">
        <ng-container *ngIf="compareResult.comparison.length <= 5;else comparisonPageSliceForPrint">
          <div class="comparison-print-page">
            <ng-container *ngTemplateOutlet="comparisonPage;context:{ compareResult:compareResult, sliceStart:0, sliceEnd:compareResult.comparison.length}"></ng-container>
          </div>
        </ng-container>
        <ng-template #comparisonPageSliceForPrint>
          <div class="no-print">
            <div class="comparison-print-page">
              <ng-container *ngTemplateOutlet="comparisonPage;context:{ compareResult:compareResult, sliceStart:0, sliceEnd:compareResult.comparison.length}"></ng-container>
            </div>
          </div>
          <div class="only-print">
            <!-- @TODO Create dymanic resolution if more than 10 product enabled for comparison -->
            <div class="comparison-print-page">
              <ng-container *ngTemplateOutlet="comparisonPage;context:{ compareResult:compareResult, slicePageNumber:1, slicePageAll:2, sliceStart:0, sliceEnd:5}"></ng-container>
            </div>
            <div class="comparison-print-page">
              <ng-container *ngTemplateOutlet="comparisonPage;context:{ compareResult:compareResult, slicePageNumber:2, slicePageAll:2, sliceStart:5, sliceEnd:compareResult.comparison.length}"></ng-container>
            </div>
          </div>
        </ng-template>
        <div class="buttons d-flex justify-content-end no-print">
          <button
            type="button"
            (click)="close()"
            class="btn btn-link text-uppercase ">
            {{ 'ssab.coil.comparison.view.button.close' | cxTranslate }}
          </button>
          <button
            type="button"
            (click)="download()"
            class="btn btn-primary ml-4">
            <i class="ssab-icon icon-system-download icon-inline pr-2"></i>
            {{ 'ssab.coil.comparison.view.button.download' | cxTranslate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #comparisonPage let-compareResult="compareResult" let-slicePageNumber="slicePageNumber" let-slicePageAll="slicePageAll" let-sliceStart="sliceStart" let-sliceEnd="sliceEnd">
  <div class="only-print mb-4">
    <div class="d-flex align-items-center text-primary">
      <div class="d-inline-block">
        <cx-page-slot position="SiteLogo"></cx-page-slot>
      </div>
      <h3 class="flex-grow-1 d-inline-block mb-3">
        {{ 'ssab.coil.comparison.page.title' | cxTranslate }}
        <ng-container *ngIf="slicePageNumber && slicePageAll">{{ slicePageNumber + '/' + slicePageAll }}</ng-container>
      </h3>
      <div class="d-inline-block mb-3">{{ printDate() | cxDate: 'longDate' }}</div>
    </div>
  </div>
  <h5>{{ 'ssab.coil.comparison.view.reference.label'|cxTranslate }}</h5>
  <div class="reference-item">
    <div class="d-flex flex-row align-items-center align-self-stretch">
      <div class="cx-product-image-container">
        <img src="assets/coil-comparison/images/coil.jpg"/>
      </div>
      <div class="product-name-wrapper">
        <div class="header">{{ compareResult.referenceCoil.coilId }}</div>
        <div>{{ compareResult.referenceCoil.dimensions }}</div>
      </div>
    </div>
    <div class="d-flex flex-row align-self-stretch">
      <div class="labels d-flex flex-column mr-3">
        <div>
          <b>{{ 'ssab.coil.comparison.COATING_CODE_TOP.label'|cxTranslate }}</b>:
        </div>
        <div>
          <b>{{ 'ssab.coil.comparison.COATING_COLOR_TOP.label'|cxTranslate }}</b>:
        </div>
      </div>
      <div class="values d-flex flex-column">
        <div>
          {{ compareResult.referenceCoil.productName }}
        </div>
        <div class="text-space-nowrap">
          <i *ngIf="compareResult.referenceCoil.colorRgb" class="ssab-icon icon-color-box icon-inline mr-2" style="background-color: rgb({{ compareResult.referenceCoil.colorRgb }})"></i>
          <span>{{ compareResult.referenceCoil.colorName }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-self-stretch">
      <div class="labels d-flex flex-column mr-3">
        <div>
          <b>{{ 'ssab.coil.comparison.view.comparable.gradeAndMetalCoating.label'|cxTranslate }}</b>:
        </div>
        <div>
          <b>{{ 'ssab.coil.comparison.view.comparable.PRODUCTION_DATE.label'|cxTranslate }}</b>:
        </div>
      </div>
      <div class="values d-flex flex-column">
        <div>
          {{ compareResult.referenceCoil.gradeAndMetalCoating }}
        </div>
        <div>
          {{ convertUIDate(compareResult.referenceCoil.productionTime)|cxDate: (getDateFormat()|async) }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row align-self-stretch" *ngIf="compareResult.referenceCoil.paintBatch">
      <div class="labels d-flex flex-column mr-3">
        <div>
          <b>{{ 'ssab.coil.comparison.view.comparable.PAINT_BATCH.label'|cxTranslate }}</b>:
        </div>
      </div>
      <div class="values d-flex flex-column">
        <div>
          {{ compareResult.referenceCoil.paintBatch }}
        </div>
      </div>
    </div>
  </div>

  <h5>{{ 'ssab.coil.comparison.view.comparison.label'|cxTranslate }}</h5>
  <div class="comparison-description no-print">
    <ng-template #comparisonDescriptionTooltip>
      <p>
        <b [innerHTML]="'ssab.coil.comparison.view.comparison.tooltip.paragraph1'|cxTranslate"></b>&nbsp;
        <span [innerHTML]="'ssab.coil.comparison.view.comparison.tooltip.paragraph2'|cxTranslate"></span>
      </p>
      <p [innerHTML]="'ssab.coil.comparison.view.comparison.tooltip.paragraph3'|cxTranslate"></p>
      <p [innerHTML]="'ssab.coil.comparison.view.comparison.tooltip.paragraph4'|cxTranslate"></p>
    </ng-template>
    <p>
      <span>{{ 'ssab.coil.comparison.view.comparison.description'|cxTranslate }}</span>
      <span class="ssab-icon icon-inline icon-system-info ml-2" role="button"
            placement="right"
            popoverClass="popover-sm popover-info" [ngbPopover]="comparisonDescriptionTooltip"
            triggers="manual" (click)="popover.open()" #popover="ngbPopover"></span>
    </p>
    <div class="risks-description">
      <div class="risk risk-green d-inline-block">
        <ng-container *ngTemplateOutlet="riskGreen"></ng-container>
        <span class="text-default">{{ 'ssab.coil.comparison.view.risk.green'|cxTranslate }}</span>
      </div>
      <div class="risk risk-yellow d-inline-block">
        <ng-container *ngTemplateOutlet="riskYellow"></ng-container>
        <span class="text-default">{{ 'ssab.coil.comparison.view.risk.yellow'|cxTranslate }}</span>
      </div>
      <div class="risk risk-red d-inline-block">
        <ng-container *ngTemplateOutlet="riskRed"></ng-container>
        <span class="text-default">{{ 'ssab.coil.comparison.view.risk.red'|cxTranslate }}</span>
      </div>
      <div class="risk risk-na d-inline-block">
        <ng-container *ngTemplateOutlet="riskRed"></ng-container>
        <span class="text-default">{{ 'ssab.coil.comparison.view.risk.gray'|cxTranslate }}</span>
      </div>
    </div>
  </div>

  <div class="comparison-table-wrapper">
    <table class="comparison-table">
      <tr>
        <th class="left-header"></th>
        <ng-container *ngFor="let coil of compareResult.comparison | slice: sliceStart:sliceEnd">
          <th>
            <div class="item">
              <div class="row no-gutters flex-nowrap">
                <div class="col d-flex align-items-center">
                  <div class="cx-product-image-container">
                    <img src="assets/coil-comparison/images/coil.jpg"/>
                  </div>
                  <div class="product-name-wrapper">
                    <div class="header">{{ coil.coil.coilId }}</div>
                    <div>{{ coil.coil.dimensions }}</div>
                    <div>{{ coil.coil.gradeAndMetalCoating }}</div>
                  </div>
                </div>
                <div class="col-auto no-print">
                  <button class="btn btn-icon m-0 p-1" (click)="removeFromComparison(coil.coil.coilId)">
                    <span class="ssab-icon icon-system-trash icon-sm"></span>
                  </button>
                </div>
              </div>

              <br/>
              <div class="row no-gutters flex-nowrap" *ngIf="coil.coil.stock">
                <div class="col row">
                    <b class="col-3">{{ 'ssab.coil.comparison.stock.address.label'|cxTranslate }}:</b>
                    <span class="col-9 product-name-wrapper">{{ coil.coil.stock.name }}</span>
                    <b class="col-3">{{ 'ssab.coil.comparison.stock.availability.label'|cxTranslate }}:</b>
                    <span class="col-9 product-name-wrapper">{{ coil.coil.stock.displayAmount }}</span>
                </div>
                <div class="col-auto no-print">
                  <div class="ssab-icon icon-inline icon-system-info ml-2" role="button"
                       *ngIf="coil.coil.stock?.note"
                       placement="right"
                       popoverClass="popover-sm popover-info" [ngbPopover]="coil.coil.stock?.note"
                       triggers="manual" (click)="popover.open()" #popover="ngbPopover">
                  </div>
                </div>
              </div>
            </div>
          </th>
        </ng-container>
      </tr>
      <ng-container *ngFor="let compTitle of compareResult.comparison[0].comparisonResult">
        <tr>
          <td class="left-header">
            <div class="d-flex justify-content-between">
              <span>{{ 'ssab.coil.comparison.view.comparable.' + compTitle.id + '.label' | cxTranslate }}</span>
              <span *ngIf="compTitle.id === 'DIFFERENCE_DA_TOP' || compTitle.id === 'DIFFERENCE_DL_TOP' || compTitle.id === 'DIFFERENCE_DB_TOP' || compTitle.id === 'DIFFERENCE_DE_TOP' "
                    class="ssab-icon icon-inline icon-system-info" role="button"
                    popoverClass="popover-sm popover-info popover-hamburger-menu ml-3" [ngbPopover]="('ssab.coil.comparison.view.comparable.' + compTitle.id + '.tooltip' | cxTranslate)"
                    triggers="mouseenter:mouseleave"
                    container="body"
                    placement="right"></span>
            </div>
          </td>
          <ng-container *ngFor="let coil of compareResult.comparison | slice: sliceStart:sliceEnd">
            <ng-container *ngFor="let comparisonResult of coil.comparisonResult">
              <td class="text-center" *ngIf="compTitle.id === comparisonResult.id">
                <ng-container *ngIf="comparisonResult.match">
                  <div class="risk risk-{{(comparisonResult.match | lowercase)}}">
                    <ng-container *ngIf="comparisonResult.match === undefined || comparisonResult.match === 'NA'">
                      <ng-container *ngTemplateOutlet="riskGrey"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="comparisonResult.match === 'GREEN'">
                      <ng-container *ngTemplateOutlet="riskGreen"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="comparisonResult.match === 'YELLOW'">
                      <ng-container *ngTemplateOutlet="riskYellow"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="comparisonResult.match === 'RED'">
                      <ng-container *ngTemplateOutlet="riskRed"></ng-container>
                    </ng-container>
                    <ng-container *ngIf="comparisonResult.diff">
                      <span class="text-default">({{ comparisonResult.diff.value }})</span>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </div>
  <div class="only-print">
    <div class="text-sm">
      <p [innerHTML]="'ssab.coil.comparison.view.comparison.tooltip.paragraph2'|cxTranslate"></p>
      <p [innerHTML]="'ssab.coil.comparison.view.comparison.tooltip.paragraph3'|cxTranslate"></p>
    </div>
  </div>
</ng-template>
<ng-template #riskGreen>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
</ng-template>
<ng-template #riskYellow>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
</ng-template>
<ng-template #riskRed>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
</ng-template>
<ng-template #riskGrey>
  <cx-icon [type]="iconTypes.CIRCLE" class="pr-2"></cx-icon>
  <span class="text-default pr-2">{{ 'ssab.coil.comparison.view.risk.na'|cxTranslate }}</span>
</ng-template>

