import {FormControl, FormGroup, UntypedFormBuilder, Validators} from '@angular/forms';

import {SsabProduct, SsabUnitOfMeasure, StepperUpdateData} from '../../../../../model/product.model';
import {DateUtils} from '../../../../../shared/util/date-utils';
import {ChangeDetectorRef, Component, Input, OnChanges, OnInit} from '@angular/core';
import {SsabNgbDateParserFormatter} from '../../../../shared/datepicker/ssab-datepicker-parser-formatter';
import {SsabCart, SsabOrderEntry} from '../../../../../model/cart.model';
import {LanguageService} from '@spartacus/core';
import {NgbDate, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {SsabCartItemBaseComponent} from '../ssab-cart-item.base.component';
import {CartItemContextSource} from '@spartacus/cart/base/components';
import {CartItemContext} from '@spartacus/cart/base/root';
import {SsabActiveCartService} from '../../../../../service/cart/ssab-active-cart.service';
import {Observable} from "rxjs";
import {SsabGlobalconfigurationService} from "../../../../../service/general/configuration/ssab-globalconfiguration.service";


@Component({
  selector: 'ssab-cx-cart-item-normal',
  templateUrl: './ssab-cart-item-normal.component.html',
  providers: [
    CartItemContextSource,
    {
      provide: CartItemContext,
      useExisting: CartItemContextSource
    }
  ]
})
export class SsabCartItemNormalComponent extends SsabCartItemBaseComponent implements OnInit, OnChanges {
  @Input() cart: SsabCart;
  @Input() minQuantity = 1;
  @Input() showPricesOrConsignmentPricesStock = true;
  itemForm: FormGroup;
  allowedDates: {};

  constructor(protected languageService: LanguageService,
              protected fb: UntypedFormBuilder,
              protected cdr: ChangeDetectorRef,
              protected activeCartService: SsabActiveCartService,
              protected cartItemContextSource: CartItemContextSource,
              protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
              private globalConfigurationService: SsabGlobalconfigurationService,
  ) {
    super(activeCartService, cartItemContextSource);
  }

  getSsabItem(): SsabOrderEntry {
    return this.item as SsabOrderEntry;
  }

  ngOnInit(): void {
    this.itemForm = new FormGroup(
      {
        unit: new FormControl(this.getSsabItem().unit.code, [Validators.required]),
        quantity: new FormControl(this.item.quantity, [Validators.required]),
        requestedDeliveryDate: new FormControl(this.convertDate(this.getSsabItem().requestedDeliveryDate), [Validators.required])
      });
    this.allowedDates = this.getSsabItem().allowedDeliveryDates?.reduce((map, date) => {
      map[date] = date;
      return map;
    }, {});

    this.setMinQuantity(this.getSsabItem().splitBundle);
  }

  ngOnChanges(): void {
    this.setMinQuantity(this.getSsabItem().splitBundle);
  }

  setMinQuantity(isSplitBundle: boolean): void {
    this.minQuantity = isSplitBundle ? this.getSsabItem().product?.stock?.bundleSize : this.getSsabItem().product?.stock?.minimumAvailableQuantity;
    if (this.minQuantity === undefined) {
      this.minQuantity = 1;
    }
    if (this.getSsabItem().splitBundle !== isSplitBundle) {
      this.postBackData(this.ngbDateParserFormatter.toDate(this.itemForm.controls.requestedDeliveryDate.value as NgbDate), this.minQuantity, isSplitBundle);
    }
  }

  postBack(): void {
    this.postBackData(this.ngbDateParserFormatter.toDate(this.itemForm.controls.requestedDeliveryDate.value as NgbDate), null);
  }

  postBackData(requestedDate: Date, quantity: number, isSplitBundle?: boolean, GTMqty?: number, GTMevent?: string): void {
    this.activeCartService.updateSsabEntry(
      {
        ...this.item,
        product: {code: this.item.product.code} as SsabProduct,
        unit: {code: this.itemForm.controls.unit.value} as SsabUnitOfMeasure,
        quantity: quantity ? quantity : this.itemForm.controls.quantity.value,
        requestedDeliveryDate: DateUtils.convertDateToOccString(this.languageService, requestedDate),
        splitBundle: isSplitBundle !== undefined ? isSplitBundle : this.getSsabItem().splitBundle,
        GTMqty,
        GTMevent
      } as SsabOrderEntry
    );
  }

  postBackQuantity(updatedQuantity: StepperUpdateData): void {
    if (updatedQuantity.qty && this.itemForm.controls.quantity.value !== updatedQuantity.qty) {
      this.postBackData(this.ngbDateParserFormatter.toDate(this.itemForm.controls.requestedDeliveryDate.value as NgbDate),
        updatedQuantity.qty, undefined, updatedQuantity.GTMqty, updatedQuantity.GTMevent);
    }
  }

  convertDate(d: string): NgbDateStruct {
    return this.ngbDateParserFormatter.parse(d);
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  isDateAllowed(date: NgbDate): boolean {
    return this.allowedDates[this.ngbDateParserFormatter.toString(date)] != null;
  }

  isHighlightedBecauseMinimumWeightNotFulfilled(item: SsabOrderEntry): boolean {
    if (item.messages) {
      for (let message of item.messages) {
        if (message.key === "ssab.cart.minimumWeightRestrictionNotFulfilled") {
          return true;
        }
      }
    }
    return false;
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }
}
