import {AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {SsabNgbDateParserFormatter} from '../../shared/datepicker/ssab-datepicker-parser-formatter';
import {DocumentTypes, SsabCertificateDocumentList, SsabDocumentList, SsabDocumentSearchRequest,} from '../../../model/document.model';
import {DateRange, Sort, SsabBaseStore} from '../../../model/misc.model';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {DateUtils} from '../../../shared/util/date-utils';
import {LanguageService, PaginationModel, BaseSiteService, WindowRef} from '@spartacus/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {BehaviorSubject, merge, Observable, of, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap, map, tap} from 'rxjs/operators';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {UserGroup} from '@spartacus/organization/administration/core';
import {B2BUnitOption, SsabUser} from '../../../model/user.model';
import {NgSelectComponent} from '@ng-select/ng-select';
import {hideSearchOptions, isSearchOptionOpen, toggleSearchOption} from '../../shared/utils/functions/ssab-functions-utils';
import {DOCUMENT} from "@angular/common";
import {SsabInputRangeComponent} from "../../../shared/input-range/ssab-input-range.component";

@Component({
  selector: 'ssab-cx-documents-search',
  templateUrl: './ssab-documents-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabDocumentsSearchComponent implements OnInit, OnDestroy, AfterViewChecked {

  @ViewChild('customers') customers: NgSelectComponent;
  @ViewChild('shipToLocation') shipToLocation: NgSelectComponent;
  @ViewChild('documentSearch') documentSearch: ElementRef<HTMLElement>;

  searchDocumentsForm: UntypedFormGroup;

  selectedDocument: string = DocumentTypes.Certificate;
  allowedCustomers$: BehaviorSubject<UserGroup[]> = new BehaviorSubject<UserGroup[]>([]);
  allowedShipToCustomers$: BehaviorSubject<UserGroup[]> = new BehaviorSubject<UserGroup[]>([]);
  documentTypes = DocumentTypes;
  documentsSize = 0;
  pagination: PaginationModel;
  defaultPageSize = 25;
  activeSort: Sort;
  certificates$: Observable<SsabCertificateDocumentList>;
  invoices$: Observable<SsabCertificateDocumentList>;
  ordersConfirmation$: Observable<SsabCertificateDocumentList>;
  transports$: Observable<SsabCertificateDocumentList>;
  customerIdsInput$ = new BehaviorSubject<string>(null);
  customerIds$: Observable<any>;
  minTermLength: number = 3;

  subscriptions = new Subscription();

  user$: Observable<SsabUser>;
  shipToLocationsInput$ = new BehaviorSubject<string>(null);
  shipToLocations$: Observable<any>;

  buyerIds$: BehaviorSubject<string>;
  hiddenDocumentTypes$: Observable<DocumentTypes[]>;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
    protected ngbDateParserFormatter: SsabNgbDateParserFormatter,
    protected userService: SsabUserService,
    protected language: LanguageService,
    private activatedRoute: ActivatedRoute,
    protected router: Router,
    private baseSiteService: BaseSiteService,
    protected winRef: WindowRef,
    @Inject(DOCUMENT) private document: Document,) {
    this.user$ = this.userService.getUserDetails();
  }

  ngOnInit(): void {
    this.getParametersFromUrl();
    this.hiddenDocumentTypes$ = this.baseSiteService.getActive().pipe(
      switchMap((baseSiteId) =>
        this.baseSiteService.get(baseSiteId)
      ),
      map((baseSite) =>
        (baseSite.baseStore as SsabBaseStore).hiddenDocumentTypes
      )
    );

    this.customerIds$ = merge(
      this.allowedCustomers$, // default items
      this.customerIdsInput$.pipe(
        filter(term => {
          return term === undefined || term?.length >= this.minTermLength || term?.length === 0
        }),
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(term => {
          // this works like this: if term length more than 3, then perform backend search
          // otherwise if term was removed - show the customerIds list as it was returned
          // by service layer order history service (might contain units that are not in commerce)
          // e.g. if commerce sends buyerId=1 but SL finds buyer,payer,consignee 1,2,3 for this request
          // so 2 and 3 for example are not in commerce but should still be searchable
          if (term?.length >= this.minTermLength) {
            return this.userService.searchUnitForCustomerIdFilter('documents', term).pipe(
              catchError(() => this.allowedCustomers$),
            )
          } else if (term === undefined || term?.length === 0) {
            return this.allowedCustomers$;
          }
        })
      )
    );

    this.buyerIds$ = new BehaviorSubject<string>(this.getBuyerIds().toString());

    this.shipToLocations$ = merge(
      this.allowedShipToCustomers$, // default items
      this.buyerIds$.pipe(
        distinctUntilChanged(),
        filter(term => {
          return term?.length >= this.minTermLength
        }),
        debounceTime(500),
        switchMap((buyerId) => {
          this.shipToLocation.clearModel();
          return this.userService.searchUnitForShipToFilter(this.selectedDocument, '', buyerId).pipe(
            catchError(() => this.allowedShipToCustomers$),
          )
        })
      ),
      this.shipToLocationsInput$.asObservable().pipe(
        distinctUntilChanged(),
        debounceTime(500),
        switchMap(term => {
            if (term === undefined) {
              return this.allowedShipToCustomers$;
            }
            return this.userService.searchUnitForShipToFilter(this.selectedDocument, term, this.getBuyerIds().toString()).pipe(
              catchError(() => this.allowedShipToCustomers$),
            );
          }
        )
      )
    );
  }

  clearShipTo(): void {
    this.shipToLocationsInput$.next(undefined);
    this.search();
  }

  clearCustomers(): void {
    this.customerIdsInput$.next(undefined);
  }

  ngAfterViewChecked(): void {
    if (this.documentSearch?.nativeElement != null) {
      setTimeout(() => {
        // Set background image height for internal user when search fields are divided to two rows
        if (this.documentSearch?.nativeElement.classList.contains("is-internal-user")) {
          const backgroundImage = this.document.querySelector('ssab-cx-background-image') as HTMLElement;
          (backgroundImage.querySelector('cx-media') as HTMLElement).style.height = '505px';
        }
      }, 100);
    }
  }

  getParametersFromUrl(): void {
    const spinner = this.document.getElementById('ssab-spinner');
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.documentType) {
          this.checkDocumentType(params.documentType);
        }
        if (params.pageSize) {
          this.defaultPageSize = +params.pageSize;
        }
        this.searchDocumentsForm = this.fb.group(
          {
            keywords: [params.keywords],
            customers: [params.customers],
            shipToLocation: [params.shipToLocation],
            creationDateStart: [params.creationDateStart ? new Date(params.creationDateStart) : new Date(new Date().getFullYear(), new Date().getMonth() - 6, new Date().getDay())], // 6  month backwards
            creationDateEnd: [params.creationDateEnd ? new Date(params.creationDateEnd) : new Date()],
          }
        );
        if (params.sort) {
          this.activeSort = {
            descending: params.sortDir === 'true', field: params.sort
          }
        }
        if (params.customers) {
          this.customerIdsInput$.next(params.customers);
        }
        if (params.shipToLocation) {
          this.shipToLocationsInput$.next(params.shipToLocation);
        }
        this.handleSearch(params.currentPage, spinner);
        this.cdr.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions?.unsubscribe();
  }

  checkDocumentType(documentType: string): void {
    switch (documentType) {
      case DocumentTypes.Certificate:
        this.selectedDocument = DocumentTypes.Certificate;
        break;
      case DocumentTypes.Invoice:
        this.selectedDocument = DocumentTypes.Invoice;
        break;
      case DocumentTypes.Confirmation:
        this.selectedDocument = DocumentTypes.Confirmation;
        break;
      case DocumentTypes.Transport:
        this.selectedDocument = DocumentTypes.Transport;
        break;
    }
    this.cdr.detectChanges();
  }

  search(currentPage = 0): void {
    const queryParams = this.getQueryParams(currentPage);
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams
      });
  }

  getQueryParams(currentPage = 0): Params {
    return {
      documentType: this.selectedDocument,
      keywords: this.searchDocumentsForm.controls.keywords.value,
      customers: this.searchDocumentsForm.controls.customers.value,
      shipToLocation: this.searchDocumentsForm.controls.shipToLocation.value,
      creationDateStart: this.getDateToString(this.searchDocumentsForm.controls.creationDateStart.value),
      creationDateEnd: this.getDateToString(this.searchDocumentsForm.controls.creationDateEnd.value),
      currentPage: currentPage,
      sort: this.activeSort.field,
      sortDir: this.activeSort.descending
    };
  }

  getDateToString(date: any): string {
    if (date) {
      if (date.year && date.month && date.day) {
        date = this.ngbDateParserFormatter.toDate(date);
      }
      return new Date(date).toDateString();
    }
    return null;
  }

  handleSearch(currentPage = 0, spinner: HTMLElement): void {
    this.subscriptions?.unsubscribe();
    this.documentsSize = 0;
    const documentRequest = this.getRequestDocumentSearch(currentPage);

    //Triggering a click on the spinner avoids endless spinner bug and documents are retrieved
    setTimeout(() => {
        spinner.click();
      },
      100);
    switch (this.selectedDocument) {
      case DocumentTypes.Certificate:
        this.certificates$ = this.userService.getCertificateDocuments(documentRequest).pipe(
          tap((documentsList) => this.filterPopulator(documentsList))
        );
        this.ordersConfirmation$ = of({});
        this.transports$ = of({});
        this.invoices$ = of({});
        break;
      case DocumentTypes.Confirmation:
        this.ordersConfirmation$ = this.userService.getOrderConfirmationDocuments(documentRequest).pipe(
          tap((documentsList) => this.filterPopulator(documentsList))
        );
        this.certificates$ = of({});
        this.transports$ = of({});
        this.invoices$ = of({});
        break;
      case DocumentTypes.Transport:
        this.transports$ = this.userService.getTransportDocuments(documentRequest).pipe(
          tap((documentsList) => this.filterPopulator(documentsList))
        );
        this.ordersConfirmation$ = of({});
        this.certificates$ = of({});
        this.invoices$ = of({});
        break;
      case DocumentTypes.Invoice:
        this.invoices$ = this.userService.getInvoiceDocuments(documentRequest).pipe(
          tap((documentsList) => this.filterPopulator(documentsList))
        );
        this.ordersConfirmation$ = of({});
        this.transports$ = of({});
        this.certificates$ = of({});
        break;
      default:
        this.certificates$ = this.userService.getCertificateDocuments(documentRequest).pipe(
          tap((documentsList) => this.filterPopulator(documentsList))
        );
        this.ordersConfirmation$ = of({});
        this.transports$ = of({});
        this.invoices$ = of({});
        break;
    }
    this.subscriptions.add(this.certificates$.subscribe());
    this.subscriptions.add(this.invoices$.subscribe());
    this.subscriptions.add(this.transports$.subscribe());
    this.subscriptions.add(this.ordersConfirmation$.subscribe());
  }

  private filterPopulator(list: SsabDocumentList): void {
    this.activeSort = {field: list.pagination.sort, descending: list.pagination.sortDescending} as Sort;
    this.allowedCustomers$.next(list.allowedCustomers);
    this.allowedShipToCustomers$.next(list.allowedShipToCustomers);
    this.documentsSize = list.pagination ? list.pagination.totalResults : 0;
    this.cdr.detectChanges();
  }

  protected getBuyerIds(): string[] {
    if (this.searchDocumentsForm.controls.customers.value) {
      return [this.searchDocumentsForm.controls.customers.value as string];
    }
    if (this.customers) {
      return this.customers.selectedValues.map((value: B2BUnitOption) => value.uid);
    }
    return [];
  }

  protected getShipToLocation(): string {
    if (this.searchDocumentsForm.controls.shipToLocation.value) {
      return this.searchDocumentsForm.controls.shipToLocation.value as string;
    }
    if (this.shipToLocation) {
      return this.shipToLocation.selectedValues.map((value: B2BUnitOption) => value.uid).toString();
    }
    return '';
  }

  sortChange(sort: Sort): void {
    this.activeSort = sort;
    this.search();
  }

  public changeDocumentType(documentType: string): void {
    const queryParams: Params = {documentType};
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams,
        replaceUrl: true
      });
  }

  isInvalid(fieldName: string): boolean {
    return this.searchDocumentsForm.get(fieldName) && this.searchDocumentsForm.get(fieldName).invalid;
  }

  convertNgbDateToOccString(d: any): string {
    if (d.year && d.month && d.day) {
      return DateUtils.convertDateToOccString(this.language, this.ngbDateParserFormatter.toDate(d));
    }
    return DateUtils.convertDateToOccString(this.language, d);
  }

  convertDate(d: any): NgbDateStruct {
    if (d.year && d.month && d.day) {
      return d;
    }
    return this.ngbDateParserFormatter.parse(d);
  }

  toggleFilter(divElement: HTMLDivElement): void {
    if ((this.isMobile() && (this.isCalendar(divElement))) || !(this.isMobile() && this.isFilterOpen(divElement))) {
      toggleSearchOption(divElement);
    }
  }

  isFilterOpen(divElement: HTMLDivElement): boolean {
    return isSearchOptionOpen(divElement);
  }

  isCalendar(divElement: HTMLDivElement): boolean {
    return divElement?.querySelector('ssab-datepicker-input') !== null;
  }

  isMobile(): boolean {
    return this.winRef.nativeWindow.matchMedia('only screen and (max-width: 760px)').matches;
  }

  hideSearchOptions(divElement: HTMLDivElement): void {
    hideSearchOptions(divElement);
  }

  hideSearchOptionsDesktop(divElement: HTMLDivElement, inputRange?: SsabInputRangeComponent): void {
    if (!this.isMobile()) {
      hideSearchOptions(divElement, inputRange);
    }
  }

  onCustomerChange(divElement: HTMLDivElement, triggerNext?: boolean): void {
    if (triggerNext) {
      this.buyerIds$.next(this.getBuyerIds().toString());
    }
  }

  showFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-documents-search')
      .querySelector('.inner.d-none');
    innerSearch.classList.remove('d-none');
    const body = ($event.target as HTMLElement)
      .closest('body');
    body.classList.add('no-scroll');
  }

  hideFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-documents-search')
      .querySelector('.inner');
    innerSearch.classList.add('d-none');
    const body = ($event.target as HTMLElement)
      .closest('body');
    body.classList.remove('no-scroll');
  }

  getRequestDocumentSearch(currentPage = 0, pageSize = 0): SsabDocumentSearchRequest {
    const documentRequest = {
      sort: this.activeSort,
      dateRange: {
        from: this.convertNgbDateToOccString(this.searchDocumentsForm.controls.creationDateStart.value),
        to: this.convertNgbDateToOccString(this.searchDocumentsForm.controls.creationDateEnd.value)
      } as DateRange,
      keyword: this.searchDocumentsForm.controls.keywords.value,
      customerIds: this.getBuyerIds(),
      consigneeId: this.getShipToLocation(),
      pageNumber: currentPage,
      pageSize: pageSize > 0 ? pageSize : this.defaultPageSize,
      docType: this.selectedDocument ? this.selectedDocument : DocumentTypes.Certificate,
      addLeadingWildCard: true,
      addTrailingWildCard: true
    } as SsabDocumentSearchRequest;

    return documentRequest;
  }

  downloadAllDocuments(): void {
    const documentRequest = this.getRequestDocumentSearch(0, this.documentsSize);

    this.userService.downloadAllDocuments(documentRequest);

  }

}

