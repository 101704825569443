<ng-container *ngIf="node$|async as node2">
  <ng-container *ngFor="let child of node2?.children">
    <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }">
    </ng-container>
  </ng-container>
</ng-container>

<!-- we generate links in a recursive manner -->
<ng-template #nav let-node="node" let-depth="depth">
  <nav (click)="toggleOpen($event)" id="{{node.uid}}" [ngClass]="node.hide?'nolink':''"
       (keydown.esc)="back()"
       *ngIf="showNavigationNode &&
      (node.title|| (node.url && (!node.children || node.children?.length === 0) ))">

    <ng-container *ngIf="node.hide!==true">
      <cx-generic-link
        *ngIf="node.url && (!node.children || node.children?.length === 0);
        else heading"
        [url]="node.url"
        [target]="node.target"
        [style]="node.styleAttributes"
        [class]="node.styleClasses"
        [ngClass]="depth> 0 ? '': 'heading-wrapper'"
        (click)="closeIfClickedTheSameLink(node)"
      >
        <div [ngClass]="{'text-bold': node.uid === 'LogoutNavNode'}">{{ node.title }}</div>
        <cx-icon *ngIf="flyout && node.children?.length > 0"
                 [type]="iconType.CARET_RIGHT"></cx-icon>
      </cx-generic-link>
    </ng-container>

    <ng-container *ngIf="node.hide===true">
      <div
        *ngIf="node.url && (!node.children || node.children?.length === 0);
        else heading"
        [style]="node.styleAttributes"
        [class]="node.styleClasses+ ' cx-no-link'"
        ngbPopover="{{ 'ssab.navigation.'+node.type+'NotAvailable' | cxTranslate }}"
        triggers="mouseenter:mouseleave"
        container="body"
        placement="bottom"
        popoverClass="popover-sm popover-info popover-hamburger-menu"
        [ngClass]="depth> 0 ? '': 'heading-wrapper'"
      >
        <div [ngClass]="{'text-bold': node.uid === 'LogoutNavNode'}">{{ node.title }}</div>
        <cx-icon *ngIf="flyout && node.children?.length > 0"
                 [type]="iconType.CARET_RIGHT"></cx-icon>
      </div>
    </ng-container>

    <ng-template #heading>
      <ng-container *ngIf="node.uid && node.uid=== 'MyAccountNavNode'; else normalHeading ">
        <h5 [attr.aria-label]="node.title"
            [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1">
          <i class="ssab-icon icon-system-profile navigation-icon icon-cursor"></i>
        </h5>
      </ng-container>
      <ng-template #normalHeading>
        <div class="heading-wrapper">
          <h4 [attr.aria-label]="node.title"
              class="text-lg d-flex"
              [attr.tabindex]="flyout && (depth === 0 || node.url) ? 0 : -1">
            <div>{{ node.title }}</div>
            <cx-icon [type]="iconType.CARET_RIGHT">
            </cx-icon>
          </h4>
        </div>
      </ng-template>
    </ng-template>

    <!-- we add a wrapper to allow for better layout handling in CSS -->
    <div class="wrapper" *ngIf="node.children?.length > 0" id="{{node.uid}}">
      <div class="childs"
           [attr.depth]="getTotalDepth(node)"
           [attr.wrap-after]="node.children?.length > wrapAfter ? wrapAfter : null"
           [attr.columns]="getColumnCount(node.children?.length)">
        <ng-container *ngFor="let child of node.children">
          <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: depth + 1 }">
          </ng-container>
        </ng-container>
      </div>
    </div>
  </nav>
</ng-template>
