import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Renderer2} from '@angular/core';
import {FacetComponent, FacetService} from '@spartacus/storefront';
import {ActivatedRoute, Router} from '@angular/router';
import {SsabFacet} from '../../../../../model/product.model';
import {FacetValue} from "@spartacus/core";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'ssab-cx-facet',
  templateUrl: './ssab-facet.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabFacetComponent extends FacetComponent {

  constructor(
    protected facetService: FacetService,
    protected elementRef: ElementRef<HTMLElement>,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    protected renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    super(facetService, elementRef, cd);
  }

  getLinkParamsForFacetRange(element?: Element, facet?: SsabFacet): void {
    const facetMin = (element.querySelector('#minQty_' + facet.code) as HTMLInputElement).value;
    const facetMax = (element.querySelector('#maxQty_' + facet.code) as HTMLInputElement).value;
    const param = ':' + this.ssabFacet.code + ':' + facetMin + ' - ' + facetMax + '';
    const replaceRegex = new RegExp("\\:?" + this.ssabFacet.code + "\\:[^:]*");
    this.router.navigate(
      ['./'],
      {
        queryParams: this.facetService.getLinkParams(this.ssabFacet.values[0].query.query.value.replace(replaceRegex, '') + param),
        relativeTo: this.route
      });
  }

  clickInputSearch(value: FacetValue): void {
    if (value) {
      this.router.navigate(
        ['./'],
        {
          queryParams: {query: value.query.query.value},
          relativeTo: this.route
        });
    }
  }

  get ssabFacet(): SsabFacet {
    return this._facet;
  }

  removeModalClassToBody(): void {
    this.renderer.removeClass(this.document.body, 'modal-open');
  }
}
