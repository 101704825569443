import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {
  AuthService,
  GlobalMessage,
  GlobalMessageActions,
  GlobalMessageType,
  RoutingService, TranslationService,
} from '@spartacus/core';
import {Location} from '@angular/common';
import {Observable, Subscription} from 'rxjs';
import {SsabUserService} from '../../../service/user/ssab-user.service';
import {SsabGtmService} from '../../../service/analytics/gtm/ssab-gtm.service';
import {map, pluck, take} from 'rxjs/operators';
import {Actions, ofType} from '@ngrx/effects';
import {CartDetailsComponent} from '@spartacus/cart/base/components';
import {CartConfigService, SelectiveCartService} from '@spartacus/cart/base/core';
import {SsabActiveCartService} from '../../../service/cart/ssab-active-cart.service';
import {SsabCart} from "../../../model/cart.model";

@Component({
  selector: 'ssab-cx-cart-details',
  templateUrl: './ssab-cart-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabCartDetailsComponent extends CartDetailsComponent implements OnInit, OnDestroy {
  // Change to true if you wanna see consigment cart
  isConsignmentCart$: Observable<boolean>;
  showPricesOrConsignmentPricesStock$: Observable<boolean>;
  subscriptions = new Subscription();

  constructor(private location: Location,
              private actions$: Actions,
              protected activeCartService: SsabActiveCartService,
              protected selectiveCartService: SelectiveCartService,
              protected authService: AuthService,
              protected routingService: RoutingService,
              protected cartConfig: CartConfigService,
              protected userService: SsabUserService,
              protected ssabGtmService: SsabGtmService,
              protected translationService: TranslationService) {

    super(activeCartService, selectiveCartService, authService, routingService, cartConfig);
    this.isConsignmentCart$ = this.activeCartService.isConsignmentCart();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.cart$.pipe(take(1))
      .subscribe((cart) => {
        if (cart?.user?.uid && cart?.code) {
          this.ssabGtmService.pushCheckoutCart(cart.user.uid, cart.code);
          this.actions$.pipe(
            ofType(GlobalMessageActions.ADD_MESSAGE),
            pluck('payload'),
            take(1),
            map((message: GlobalMessage) => {
              if (message.type === GlobalMessageType.MSG_TYPE_ERROR) {
                this.translationService.translate(message.text.key)
                  .pipe(take(1))
                  .subscribe(translated => this.ssabGtmService.pushErrorNotification('checkout notification', translated));
              }
            })
          ).subscribe();
        }
      });
    this.subscriptions.add(
      this.isConsignmentCart$.subscribe((isConsignment) => {
        this.showPricesOrConsignmentPricesStock$ = this.userService.isShowPricesOrConsignmentStockPrices(!isConsignment);
      })
    );
  }

  getSsabCart(): Observable<SsabCart> {
    return this.cart$ as Observable<SsabCart>;
  }

  back(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
