import {FeedbackType, SsabOrder, SsabOrderFeedbackData, SsabOrderFeedbackForm} from '../../../../../model/order.model';
import {ChangeDetectionStrategy, Component, Inject, Input, OnInit, ChangeDetectorRef,} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, UntypedFormBuilder, Validators, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DOCUMENT} from '@angular/common';
import {SsabUserOrderConnector} from "../../../../../service/user/ssab-user-order.connector";
import {FormUtils} from "../../../../../shared/util/form-utils";
import {DateUtils} from "../../../../../shared/util/date-utils";

@Component({
  selector: 'ssab-cx-order-data-tables-feedback',
  templateUrl: './ssab-order-data-tables-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SsabOrderFeedbackTablesComponent implements OnInit {

  RATINGS = [1, 2, 3, 4];

  @Input() order: SsabOrder;

  feedbackForm: FormGroup;
  submitClicked = false;
  submitOk = false;
  hideInputs = false;

  constructor(
    protected fb: UntypedFormBuilder,
    protected cdr: ChangeDetectorRef,
    protected userOrderConnector: SsabUserOrderConnector,
    @Inject(DOCUMENT) private document: Document,
  ) {
  }

  ngOnInit(): void {
    this.hideInputs = this.order.feedbacks?.length > 0;

    this.feedbackForm = this.fb.group({
      rating_QUALITY: new FormControl(null, Validators.required),
      comment_QUALITY: new FormControl(null),
      rating_DELIVERY: new FormControl(null, Validators.required),
      comment_DELIVERY: new FormControl(null),
      rating_SERVICE: new FormControl(null, Validators.required),
      comment_SERVICE: new FormControl(null),
    });
    this.feedbackForm.get('rating_QUALITY').setValidators(this.validator(FeedbackType.QUALITY, true));
    this.feedbackForm.get('comment_QUALITY').setValidators(this.validator(FeedbackType.QUALITY, false));
    this.feedbackForm.get('rating_DELIVERY').setValidators(this.validator(FeedbackType.DELIVERY, true));
    this.feedbackForm.get('comment_DELIVERY').setValidators(this.validator(FeedbackType.DELIVERY, false));
    this.feedbackForm.get('rating_SERVICE').setValidators(this.validator(FeedbackType.SERVICE, true));
    this.feedbackForm.get('comment_SERVICE').setValidators(this.validator(FeedbackType.SERVICE, false));
  }


  validator(id: FeedbackType, forRating: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      let comment = control.parent?.get('comment_' + id).value ?? '';
      let rating = control.parent?.get('rating_' + id).value;
      if (forRating) {
        return rating === null ? {required: control.value} : null;
      }
      return comment.length === 0 && this.isCommentMandatory(id) ? {required: control.value} : null;
    }
  }

  protected readonly FeedbackType = FeedbackType;

  submit(): void {
    this.submitClicked = true;
    if (this.feedbackForm.valid) {
      const feedback = {
        feedBacks: [
          this.createFormData(FeedbackType.QUALITY),
          this.createFormData(FeedbackType.DELIVERY),
          this.createFormData(FeedbackType.SERVICE),
        ]
      } as SsabOrderFeedbackForm;
      const mill = this.order.entries?.map(e => e.warehouse?.name).find(Boolean);
      this.userOrderConnector.sendOrderFeedback(this.order.code, this.order.orderSource, mill, this.order.unit.uid, feedback);
      this.order = {...this.order, feedbacks: [{...feedback, commentedAt: new Date().toISOString()}, ...this.order.feedbacks]};
      this.resetForm();
      this.hideInputs = true;
      this.submitOk = true;
      this.cdr.detectChanges();
    }
  }

  private createFormData(id: FeedbackType): SsabOrderFeedbackData {
    return {
      id,
      rating: this.feedbackForm.get('rating_' + id).value,
      comment: this.feedbackForm.get('comment_' + id).value,
    };
  }

  cancel(): void {
    this.resetForm();
    this.hideInputs = true;
  }

  resetForm(): void {
    this.feedbackForm.reset();
    this.submitClicked = false;
    this.document.querySelectorAll('.rating-input .icon-star-selected')
      .forEach(e => e.classList.remove('icon-star-selected'));
  }

  starFocus($event: FocusEvent, star: number, id: FeedbackType, cssClass: string = 'icon-star-focused'): void {
    for (let i = 1; i <= star; i++) {
      this.document.querySelectorAll('#rating-star-' + id + '-' + i)
        .forEach((e) => e.classList.add(cssClass));
    }
  }

  starBlur(id: FeedbackType): void {
    this.document
      .querySelectorAll('.rate-us .icon-star-focused')
      .forEach((e) => e.classList.remove('icon-star-focused'));
  }

  starClick($event: FocusEvent, star: number, id: FeedbackType): void {
    this.document.querySelectorAll('.rating-star-' + id)
      .forEach(e => e.classList.remove('icon-star-selected'));
    this.starFocus($event, star, id, 'icon-star-selected');
    this.feedbackForm.get('rating_' + id)?.setValue(star);
    this.updateFormValidity();
  }

  isNotValid(id: string): boolean {
    return FormUtils.isNotValidField(
      this.feedbackForm,
      id,
      this.submitClicked
    );
  }

  updateFormValidity(): void {
    Object.values(this.feedbackForm.controls).forEach(v => v.updateValueAndValidity());
  }

  isFeedbackAllowed(): boolean {
    return this.order.allowFeedback;
  }

  isCommentMandatory(id: any): boolean {
    let rating = this.feedbackForm.get('rating_' + id).value;
    return rating !== null && parseInt(rating) < 4;
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  showInputs() {
    this.clearMessage();
    this.hideInputs = false;
  }

  clearMessage() {
    this.submitOk = false;
  }
}

