<!-- SEARCH CRITERIA -->
<form [formGroup]="searchDocolForm" (ngSubmit)="search()" class="cx-material-data-form d-none d-lg-block pb-lg-5">
  <div class="cx-search-form row">
    <h3 class="col-12 pb-5 mb-3 d-lg-none d-md-block mb-3">{{'ssab.search.criteria' | cxTranslate}}
      <i class="ssab-icon icon-navigation-close icon-cursor float-right" (click)="hideFilterOptions($event)"></i>
    </h3>

    <h3 class="col-12 text-white pb-4 d-none d-lg-block">{{'ssab.search.criteria' | cxTranslate}}</h3>

    <div class="form-group cx-material-data-search-option col-lg-6 col-12">
      <label class="text-lg">
        {{'ssab.material.data.grade.title' | cxTranslate}}
        <span class="ssab-icon icon-inline"></span>
      </label>
      <ssab-cx-input class="pr-0 input-no-border"
                     [typeInput]="'text'"
                     [idName]="'keyword'"
                     [nameForm]="'keyword'"
                     [parentForm]="searchDocolForm"
                     [isNotValidFunction]="isInvalid('keyword')"
                     [labelPlaceholder]="'ssab.material.data.grade.placeholder'"
                     (afterEnter)="afterEnter($event)">
      </ssab-cx-input>
    </div>

    <div #divYieldStress class="form-group cx-material-data-search-option cx-option-hide col-lg-3 col-12"
         *ngIf="docolProductList?.yieldStress && docolProductList?.yieldStress.to >0">
      <h4 class="option-header">
        <span (click)="hideSearchOptions(divYieldStress, inputRangeYieldStress)">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"></i>
          {{'ssab.selectRange' | cxTranslate}}
        </span>
        <i class="float-right ssab-icon icon-navigation-close icon-inline"
           (click)="hideSearchOptions(divYieldStress, inputRangeYieldStress)"></i>
      </h4>
      <label class="text-lg">{{'ssab.material.data.yield.label' | cxTranslate}}
        <span class="ssab-icon icon-inline"></span>
      </label>
      <div class="value facet-range vertical-align-item">
        <ssab-cx-input-range #inputRangeYieldStress
                             [inputValueFrom]="docolProductList?.yieldStress.from"
                             [inputValueTo]="docolProductList?.yieldStress.to"
                             [inputValueStep]="1"
                             [sliderForm]="searchDocolForm.get('yieldStress')"
                             (inputRangeOpen)="toggleFilter(divYieldStress)"
                             (inputRangeClosed)="hideSearchOptions(divYieldStress, inputRangeYieldStress)"
                             class="w-100"
        ></ssab-cx-input-range>
      </div>
    </div>

    <div #divTensileStress class="form-group cx-material-data-search-option cx-option-hide col-lg-3 col-12"
         *ngIf="docolProductList?.tensileStress && docolProductList?.tensileStress.to >0">
      <h4 class="option-header">
        <span (click)="hideSearchOptions(divTensileStress, inputRangeTensileStress)">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"></i>
          {{'ssab.selectRange' | cxTranslate}}
        </span>
        <i class="float-right ssab-icon icon-navigation-close icon-inline"
           (click)="hideSearchOptions(divTensileStress, inputRangeTensileStress)"></i>
      </h4>
      <label class="text-lg">{{'ssab.material.data.tensile.label' | cxTranslate}}
        <span class="ssab-icon icon-inline"></span>
      </label>
      <div class="value facet-range vertical-align-item">
        <ssab-cx-input-range #inputRangeTensileStress
                             [inputValueFrom]="docolProductList?.tensileStress.from"
                             [inputValueTo]="docolProductList?.tensileStress.to"
                             [inputValueStep]="1"
                             [sliderForm]="searchDocolForm.get('tensileStress')"
                             (inputRangeOpen)="toggleFilter(divTensileStress)"
                             (inputRangeClosed)="hideSearchOptions(divTensileStress, inputRangeTensileStress)"
                             class="w-100"
        ></ssab-cx-input-range>
      </div>
    </div>

    <!!--This is for making a break in the columns so buttons can be separated -->
    <div class="w-100 pb-3 d-none d-lg-block"></div>

    <div #divAllowedTypes
         class="form-group cx-material-data-search-option cx-material-data-search-select col-lg-3 col-12">
      <h4 class="option-header">
        <span (click)="hideSearchOptions(divAllowedTypes)">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"></i>
          {{'ssab.material.data.type.label' | cxTranslate}}
        </span>
        <i class="float-right ssab-icon icon-navigation-close icon-inline"
           (click)="hideSearchOptions(divAllowedTypes)"></i>
      </h4>
      <label class="text-lg">{{'ssab.material.data.type.label' | cxTranslate}}
        <span class="ssab-icon icon-inline"></span>
      </label>
      <ng-select #types
                 [items]="docolProductList?.allowedTypes"
                 [searchable]="true"
                 [multiple]="true"
                 [closeOnSelect]="true"
                 [clearable]="true"
                 [isOpen]="isFilterOpen(divAllowedTypes)"
                 (blur)="hideSearchOptionsDesktop(divAllowedTypes)"
                 (close)="hideSearchOptionsDesktop(divAllowedTypes)"
                 (click)="toggleFilter(divAllowedTypes)"
                 class="ssab-select-footer"
                 dropdownPosition="bottom"
                 id="types"
                 formControlName="types"
                 placeholder="{{ 'ssab.material.data.type.selectAll' | cxTranslate }}">
        <ng-template ng-option-tmp let-item="item">
          <span title="{{item}}">{{item}}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <span title="{{item}}">{{item}}</span>
        </ng-template>
        <ng-template ng-footer-tmp>
          <p class="w-100 mb-0"
             (click)="resetSelect(types)">{{ 'ssab.material.data.type.selectAll' | cxTranslate }}</p>
        </ng-template>
      </ng-select>
    </div>

    <div #divAllowedStandards
         class="form-group cx-material-data-search-option cx-material-data-search-select col-lg-3 col-12">
      <h4 class="option-header">
        <span (click)="hideSearchOptions(divAllowedStandards)">
          <i class="ssab-icon icon-navigation-chevron-left icon-inline pr-3"></i>
          {{'ssab.material.data.standard.label' | cxTranslate}}
        </span>
        <i class="float-right ssab-icon icon-navigation-close icon-inline"
           (click)="hideSearchOptions(divAllowedStandards)"></i>
      </h4>
      <label class="text-lg">{{'ssab.material.data.standard.label' | cxTranslate}}
        <span class="ssab-icon icon-inline"></span>
      </label>
      <ng-select #standards
                 [items]="docolProductList?.allowedStandards"
                 [searchable]="true"
                 [multiple]="true"
                 [closeOnSelect]="true"
                 [clearable]="true"
                 [isOpen]="isFilterOpen(divAllowedStandards)"
                 (blur)="hideSearchOptionsDesktop(divAllowedStandards)"
                 (close)="hideSearchOptionsDesktop(divAllowedStandards)"
                 (click)="toggleFilter(divAllowedStandards)"
                 class="ssab-select-footer"
                 dropdownPosition="bottom"
                 id="standards"
                 formControlName="standards"
                 placeholder="{{ 'ssab.material.data.standard.selectAll' | cxTranslate }}">
        <ng-template ng-option-tmp let-item="item">
          <span title="{{item}}">{{item}}</span>
        </ng-template>
        <ng-template ng-label-tmp let-item="item">
          <span title="{{item}}">{{item}}</span>
        </ng-template>
        <ng-template ng-footer-tmp>
          <p class="w-100 mb-0"
             (click)="resetSelect(standards)">{{ 'ssab.material.data.standard.selectAll' | cxTranslate }}</p>
        </ng-template>
      </ng-select>
    </div>

    <div
      class="col-12 col-md-3 d-flex justify-content-md-end justify-content-center form-group ml-md-auto align-self-end">
      <button
        (click)="reset()"
        class="btn btn-link btn-default-height text-uppercase text-right p-0"
        type='button'>
        {{'ssab.search.reset' | cxTranslate}}
      </button>
    </div>

    <div class="col-12 col-md-6 col-lg-3 form-group align-self-end">
      <button
        class="btn btn-primary btn-default-height btn-block"
        type="submit">
        {{ 'ssab.search.resultTitle' | cxTranslate }}
      </button>
    </div>
  </div>
</form>
