import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Actions} from '@ngrx/effects';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, of, Subscription} from "rxjs";
import {SsabCoil, SsabCoilComparisonResponse, SsabCoilSearchRequest, SsabCoilSearchResult, SsabFacet} from "../../model/product.model";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {distinctUntilChanged, filter, map, tap} from "rxjs/operators";
import {SsabProductService} from "../../service/product/ssab-product.service";
import {Sort, TablesTypes} from "../../model/misc.model";
import {DateUtils} from "../../shared/util/date-utils";
import {SsabGlobalconfigurationService} from "../../service/general/configuration/ssab-globalconfiguration.service";
import {SsabCoilsComparisonService} from "../../service/product/ssab-coils-comparison.service";
import {LanguageService, WindowRef} from "@spartacus/core";

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_FACETS = [{code: 'PRODUCTION_DATE', values: [{code: '6', selected: true}]}];

@Component({
  selector: 'ssab-cx-coils-search',
  templateUrl: './ssab-coils-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoilsSearchComponent implements OnDestroy, OnInit {
  subscriptions = new Subscription();
  currentPage = 0;
  pageSize = DEFAULT_PAGE_SIZE;
  form: UntypedFormGroup;
  activeSort: Sort;

  searchResult$: Observable<SsabCoilSearchResult>;
  facets$: Observable<SsabFacet[]>;
  compareResult$: Observable<SsabCoilComparisonResponse>;

  facetPrefix = 'FACET_';

  constructor(protected productService: SsabProductService,
              private activatedRoute: ActivatedRoute,
              protected router: Router,
              protected fb: UntypedFormBuilder,
              private globalConfigurationService: SsabGlobalconfigurationService,
              protected coilsComparisonService: SsabCoilsComparisonService,
              protected cdr: ChangeDetectorRef,
              protected actions$: Actions,
              protected winRef: WindowRef,
              protected languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.compareResult$ = this.coilsComparisonService.getCompareCoils(0, 9999);
    this.getParametersFromUrl();
  }

  getParametersFromUrl(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        if (params.q) {
          this.setSearchResult(JSON.parse(params.q), false)
          this.cdr.detectChanges();
        } else {
          this.setSearchResult(this.getSearchBody(), false);
        }
      })
    );
    let firstTime = true;
    this.subscriptions.add(
      this.languageService.getActive().pipe(
        filter(f => {
          const skip = firstTime;
          firstTime = false;
          return !skip;
        }),
        distinctUntilChanged()
      ).subscribe(
        () => this.setSearchResult(this.getSearchBody(), false)
      ));
  }

  updateFacets(): void {
    // only update facet values, no search result needed yet
    const sub = this.executeSearch({...this.getSearchBody(), size: 1}).subscribe(
      result => {
        this.initForm(result, true);
        this.facets$ = of(result.facets);
        this.cdr.detectChanges();
        sub.unsubscribe();
      }
    )
  }

  setSearchResult(request: SsabCoilSearchRequest, facetSearch: boolean): void {
    this.searchResult$ = this.executeSearch(request).pipe(
      tap(result => {
          this.initForm(result, facetSearch);
          this.facets$ = of(result.facets);
        }
      )
    );
  }

  executeSearch(request: SsabCoilSearchRequest): Observable<SsabCoilSearchResult> {
    return this.productService.searchCoils(request).pipe(
      // withLatestFrom(this.searchResult$ ?? of()),
      map(
        (result) => ({
          ...result,
          pagination: {
            ...result.pagination,
            totalResults: (result.pagination as any)?.totalNumberOfResults,
            totalPages: (result.pagination as any)?.numberOfPages
          }
        })
      ));
  }

  search(initSearch?: boolean): void {
    if (initSearch) {
      this.currentPage = 0;
    }
    const queryParams = JSON.stringify(this.getSearchBody());
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {q: queryParams}
      });
  }

  private initForm(result: SsabCoilSearchResult, facetSearch: boolean) {
    const controls = {
      coilId: [result.coilId],
      searchTerm: [result.searchTerm],
      'FACET_THICKNESS': [],
      showStocks: [result.showStocks]
    };
    result.facets?.forEach(
      f => controls[this.facetPrefix + f.code] = [f.values?.find(ff => ff.selected)?.code ?? null]
    )
    this.form = this.fb.group(controls);
    const thickessValue = result.facets
      .filter(f => 'THICKNESS' === f.code)
      .flatMap(f => f.values)
      .map(v => v.code);
    this.form.controls.FACET_THICKNESS.setValue(thickessValue);
    if (!facetSearch) {
      this.currentPage = result.pagination?.currentPage;
      this.pageSize = result.pagination?.pageSize;
      if (result.sorts) {
        this.activeSort = {
          field: result.sorts[0].field,
          descending: result.sorts[0].descending
        };
      }
    }
  }

  private getSearchBody(): SsabCoilSearchRequest {
    return {
      searchTerm: this.form?.controls.searchTerm.value ?? null,
      coilId: this.form?.controls.coilId.value ?? null,
      facets: this.form ?
        Object.keys(this.form?.controls)
          .filter(key => key.startsWith(this.facetPrefix))
          .map(key => {
            const facetCtrl = this.form.controls[key];
            const range = key.indexOf('THICKNESS') > 0;
            return {
              code: key.replace(this.facetPrefix, ''),
              values: range ?
                facetCtrl.value?.map(val => ({code: val})) :
                facetCtrl.value != null ? [{
                  code: facetCtrl.value,
                  selected: true
                }] : []
            } as SsabFacet;
          })
          .filter(f =>
            f.values?.length > 0
          ) :
        DEFAULT_FACETS,
      sorts: this.activeSort ? [this.activeSort] : [],
      from: this.currentPage * this.pageSize,
      size: this.pageSize,
      hideStocks: this.form === undefined || this.form?.controls.showStocks.value === false
    } as SsabCoilSearchRequest;
  }

  sort(sort: Sort): void {
    this.activeSort = sort;
    this.search();
  }

  convertUIDate(d: string): Date {
    return DateUtils.convertDate(d);
  }

  getDateFormat(): Observable<string> {
    return this.globalConfigurationService.getDateFormat();
  }

  toggleComparison(id: string): void {
    this.coilsComparisonService.toggleComparison(id);
  }

  reset(): void {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        onSameUrlNavigation: 'reload',
        queryParams: {}
      });
  }

  pageChange(currentPage: number): void {
    this.currentPage = currentPage;
    this.search();
  }

  setPageSize(pageSize: number) {
    this.pageSize = pageSize;
    this.search();
  }

  showFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-coils-search')
      .querySelector('.cx-coils-form.d-none');
    innerSearch.classList.remove('d-none');
    const body = ($event.target as HTMLElement)
      .closest('body');
    body.classList.add('no-scroll');
  }

  hideFilterOptions($event: MouseEvent): void {
    const innerSearch = ($event.target as HTMLElement)
      .closest('.cx-coils-search')
      .querySelector('.cx-coils-form');
    innerSearch.classList.add('d-none');
    const body = ($event.target as HTMLElement)
      .closest('body');
    body.classList.remove('no-scroll');
  }

  getCompareClass(compareResult: SsabCoilComparisonResponse, coil: SsabCoil) {
    return coil.coilId === compareResult.referenceCoil?.coilId ?
      'recerence-coil' :
      compareResult.comparison?.find(cc => cc.coil.coilId === coil.coilId) !== undefined ?
        'compare-coil' :
        !this.isCoilAllowedToBeAdded(coil, compareResult) ?
          'compare-disabled' :
          ''
  }

  getCompareTooltipKey(coil: SsabCoil, compareResult: SsabCoilComparisonResponse): string {
    if (compareResult.referenceCoil === undefined && compareResult.comparison.length > 0) {
      return this.getCompareTooltipKeyForReference(coil, compareResult.comparison[0].coil, compareResult);
    }
    return this.getCompareTooltipKeyForReference(coil, compareResult.referenceCoil, compareResult);
  }

  private getCompareTooltipKeyForReference(coil: SsabCoil, referenceCoil: SsabCoil, compareResult: SsabCoilComparisonResponse): string {
    return compareResult.referenceCoil ?
      coil.coilId === referenceCoil?.coilId ?
        'ssab.coil.comparison.compare.remove.comparison.tooltip' :
        !this.isCoilAllowedToBeAddedForReference(coil, referenceCoil) ?
          this.getDisabledTooltip(coil, referenceCoil) :
          compareResult.comparison?.find(cc => cc.coil.coilId === coil.coilId) !== undefined ?
            'ssab.coil.comparison.compare.remove.comparison.tooltip' :
            'ssab.coil.comparison.compare.add.comparison.tooltip' :
      'ssab.coil.comparison.compare.add.reference.tooltip'
  }

  private getDisabledTooltip(coil: SsabCoil, referenceCoil: SsabCoil): string {
    let reason = 'product';
    if (referenceCoil.colorCode !== coil.colorCode) {
      reason = 'color'
      if (referenceCoil.product !== coil.product) {
        reason = 'productAndColor'
      }
    }
    return 'ssab.coil.comparison.compare.disabled.' + reason + '.tooltip';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  isCoilAllowedToBeAdded(coil: SsabCoil, compareResult: SsabCoilComparisonResponse): boolean {
    if (compareResult.referenceCoil === undefined) {
      if (compareResult.comparison.length > 0) {
        return this.isCoilAllowedToBeAddedForReference(coil, compareResult.comparison[0].coil);
      }
      return true;
    }
    return this.isCoilAllowedToBeAddedForReference(coil, compareResult.referenceCoil);
  }

  private isCoilAllowedToBeAddedForReference(coil: SsabCoil, referenceCoil: SsabCoil): boolean {
    if (referenceCoil === undefined) {
      return true;
    }
    return referenceCoil.colorCode === coil.colorCode && referenceCoil.product === coil.product;
  }

  getTableHeadType(searchResult: SsabCoilSearchResult): TablesTypes {
    return searchResult.showStocks ? TablesTypes.CoilsWithStock : TablesTypes.CoilsWithoutStock;
  }
}
