<ng-container *ngIf="order$ | async as order">
  <div class="order-summary cart-header" *ngIf="!(isConsignmentCart$|async); else consignmentOrderConfirmationHeader">
    <div class="row divs-same-height" *ngIf="configuration$ | async as configuration">
      <!-- contact -->
      <div class="col-lg-6 col-12 contact-details">
        <div class="header-block h-100">
          <div class="field-header pb-4">
            {{ 'ssab.cart.header.contact'|cxTranslate }}
          </div>

          <div class="field-content">
            <label>{{ 'ssab.cart.header.buyer'|cxTranslate }}</label>
            <div class="field-value">
              {{ order.buyerAddress?.companyName + " - " + order.buyerAddress?.formattedAddress }}
            </div>
          </div>

          <div class="field-content" *ngIf="order.payerAddress">
            <label>{{ 'ssab.cart.header.payer'|cxTranslate }}</label>
            <div class="field-value">
              {{ order.payerAddress?.companyName + " - " + order.payerAddress?.formattedAddress }}
            </div>
          </div>

          <div class="field-content" *ngIf="order.billingAddress">
            <label>{{ 'ssab.cart.header.billingAddress'|cxTranslate }}</label>
            <div class="field-value">
              {{ order.billingAddress?.formattedAddress }}
            </div>
          </div>

          <div class="field-content ">
            <label>{{ 'ssab.cart.header.orderConfirmationEmail'|cxTranslate }}</label>
            <div *ngIf="order?.b1OrderConfirmationReceivers?.length > 0; else orderConfirmationEmail">
              <div class="field-value text-truncate" *ngFor="let receiver of order.b1OrderConfirmationReceivers">
                {{ receiver.email }}
              </div>
              <div class="field-value text-truncate">
                {{ order.b1OrderConfirmationEmail }}
              </div>
            </div>
            <ng-template #orderConfirmationEmail>
              <div class="field-value text-truncate">
                {{ order.orderConfirmationEmail }}
              </div>
            </ng-template>
          </div>
          <div class="field-content" *ngIf="configuration.checkout.showDeliveryNoteEmailSection">
            <label>{{ 'ssab.cart.header.deliveryNoteEmail'|cxTranslate }}</label>
            <div class="field-value text-truncate">
              {{ order.deliveryNoteEmail }}
            </div>
          </div>

          <div class="field-content" *ngIf="configuration.checkout.showMaterialCertificateEmail">
            <label>{{ 'ssab.cart.header.materialCertificateEmail'|cxTranslate }}</label>
            <div class="field-value text-truncate">
              {{ order.materialCertificateEmail }}
            </div>
          </div>

          <div class="field-content">
            <label>{{ 'ssab.cart.header.purchaseOrderNumber.label'|cxTranslate }}</label>
            <div class="field-value">
              {{ order.purchaseOrderNumber }}
            </div>
          </div>
        </div>
      </div>

      <!-- delivery -->
      <div class="col-lg-6 col-12 delivery-details">

        <div class="header-block delivery-details h-100">

          <div class="field-header pb-4">
            {{ 'ssab.cart.header.delivery.label'|cxTranslate }}
          </div>
          <div class="field-content">
            <label>{{ 'ssab.cart.header.deliveryMode.title'|cxTranslate }}</label>
            <div class="field-value">
              {{ order.deliveryMode?.name }}
            </div>
          </div>

          <ng-container *ngIf="order.deliveryMode && order.deliveryMode?.pickup && !configuration.checkout.showPdfTransportationPickup">
            <div class="field-content" *ngIf="user$|async as user">
              <label></label>
              <div class="field-value" [innerHTML]="'ssab.cart.header.pickupInfo1'|cxTranslate">
              </div>
              <label></label>
              <div class="field-value" [innerHTML]="'ssab.cart.header.pickupInfo2'|cxTranslate: {SalesContactsEmail: emails(user)}">
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="order.deliveryMode && !order.deliveryMode?.pickup">

            <div class="field-content">
              <label>{{ 'ssab.cart.header.deliveryAddress'|cxTranslate }}</label>
              <div class="field-value">
                {{ order.deliveryAddress?.formattedAddress }}
              </div>
            </div>

            <div class="field-content" *ngIf="configuration.checkout.showUnloadingMethod">
              <label>{{ 'ssab.cart.header.unloadingMethod'|cxTranslate }}</label>
              <div class="field-value">
                {{ order.unloadingMethod?.name }}
              </div>
            </div>

            <div class="field-content">
              <label>{{ 'ssab.cart.header.incoTerm.title'|cxTranslate }}</label>
              <div class="field-value" [innerHTML]="order.incoTerm1 + ' ' +order.incoTerm2">
              </div>
            </div>

            <div class="field-content" *ngIf="configuration.checkout.showFreeTextForWarehouse && order.freeTextForWarehouse?.length > 0">
              <label class="d-inline-block">{{ 'ssab.cart.header.freeTextForWarehouse.label'|cxTranslate }}</label>
              <div class="field-value">
                {{ order.freeTextForWarehouse }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #consignmentOrderConfirmationHeader>
    <div class="header-total order-totals mb-4">
      <div class="row justify-content-end">
        <div class="col-lg-4 col-md-5 col-12">
          <label class="text-opacity">{{ 'ssab.cart.header.purchaseOrderNumber.label'|cxTranslate }}</label>
          <div class="field-value">
            {{ order.purchaseOrderNumber }}
          </div>
        </div>
        <div class="col-lg-3 col-sm-4 col-8 total-weight pt-sm-0 pt-3 ml-sm-auto mr-3 mr-sm-0">
          <div class="pb-2 row">
            <span class="col-6">{{ 'ssab.cart.header.price.total'|cxTranslate }}</span>
            <div class="col-6"><span [innerHTML]="order.subTotal?.formattedValue"></span></div>
          </div>
          <div class="pb-2 row">
            <span class="col-6">{{ 'ssab.cart.header.totalWeight'|cxTranslate }}</span>
            <div class="col-6">{{ order.totalDisplayWeight }} <span [innerHTML]="order.weightUnit?.name"></span></div>
          </div>
          <div class="pb-2 row">
            <span class="col-6">{{ 'ssab.cart.header.price.totalTax'|cxTranslate }}</span>
            <div class="col-6"><span *ngIf="order.totalTax && order.totalTax.formattedValue">{{ order.totalTax?.formattedValue }} </span></div>
          </div>
          <div class="row">
            <div class="col-6 text-uppercase total-sum">
              <label class="text-lg">{{ 'ssab.cart.header.price.totalPriceWithTax'|cxTranslate }}</label>
            </div>
            <div class="col-6 total-sum text-bold">
              <div class="field-value">{{ order.totalPriceWithTax?.formattedValue }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>


