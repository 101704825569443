import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {CmsConfig, ConfigModule, I18nModule, provideDefaultConfig, UrlModule} from '@spartacus/core';
import {CarouselModule, IconModule, MediaModule, OutletModule, PageSlotModule, ProductImagesModule} from '@spartacus/storefront';
import {ClaimRequestFormComponent} from "./claim-request/ssab-claim-request-form.component";
import {SsabInputModule} from "../../shared/input/ssab-input.module";
import {NgSelectModule} from "@ng-select/ng-select";
import {SsabTableHeadModule} from "../shared/table-head/ssab-table-head.module";
import {ReactiveFormsModule} from "@angular/forms";
import {SsabPaginationBottomModule} from '../../shared/pagination-bottom/ssab-pagination-bottom.module';
import {NgbPopoverModule} from "@ng-bootstrap/ng-bootstrap";
import {SsabInputRangeModule} from "../../shared/input-range/ssab-input-range.module";
import {SsabAddEmailModule} from "../shared/add-emails/ssab-add-email.module";
import {ClaimsListComponent} from "./ssab-claims.component";
import {SsabDatepickerModule} from "../shared/datepicker/ssab-datepicker.module";
import {SsabDocumentsSearchModule} from "../my-account/documents-search/ssab-documents-search.module";
import {SsabOrderHistoryModule} from "../my-account/order/order-history/ssab-order-history.module";
import {ClaimListItemComponent} from "./claim-list-item/claim-list-item.component";
import {ChangeAssigneeDialogComponent} from "./change-assignee-dialog/change-assignee-dialog.component";
import {changeAssigneeLayout} from "./change-assignee-dialog/change-assignee-dialog-layout.config";
import {ChangeStatusDialogComponent} from "./change-status-dialog/change-status-dialog.component";
import {changeStatusLayout} from './change-status-dialog/change-status-dialog-layout.config';
import {ClaimMessagesComponent} from "./claim-messages/ssab-claim-messages.component";
import {SsabPipesModule} from "../shared/utils/safe-html/ssab-pipes.module";


@NgModule({
  imports: [CommonModule,
    OutletModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ClaimRequestFormComponent: {
          component: ClaimRequestFormComponent
        },
        ClaimsListComponent: {
          component: ClaimsListComponent
        },
      },
    } as CmsConfig), ProductImagesModule, CarouselModule, SsabInputModule, NgSelectModule, SsabTableHeadModule, ReactiveFormsModule, MediaModule, NgbPopoverModule, SsabPaginationBottomModule, UrlModule, IconModule, PageSlotModule, SsabInputRangeModule, SsabAddEmailModule, SsabDatepickerModule, SsabDocumentsSearchModule, SsabOrderHistoryModule, SsabPipesModule,
  ],
  declarations: [ClaimRequestFormComponent, ClaimsListComponent, ClaimListItemComponent, ChangeAssigneeDialogComponent, ChangeStatusDialogComponent, ClaimMessagesComponent],
  exports: [ClaimRequestFormComponent, ClaimsListComponent, ClaimListItemComponent, ChangeAssigneeDialogComponent, ChangeStatusDialogComponent, ClaimMessagesComponent],
  providers: [provideDefaultConfig(changeAssigneeLayout), provideDefaultConfig(changeStatusLayout)],
})
export class SsabClaimsModule {
}
