<ng-container *ngIf="!isFeedbackAllowed();else feedbackAllowed">
  <p>
    {{ 'ssab.order.history.feedback.notavailable' | cxTranslate }}
  </p>
</ng-container>

<ng-template #feedbackAllowed>

  <div class="alert alert-success text-left" *ngIf="submitOk">
    <div class="container">
      <span class="text-left" [innerHTML]="'ssab.order.history.feedback.submitted' | cxTranslate |safeHtml"></span>
      <button
        class="close"
        type="button"
        (click)="clearMessage()"
      >
        <span class="ssab-icon icon-white icon-navigation-close"></span>
      </button>
    </div>
  </div>

  <button type="submit" *ngIf="hideInputs" class="btn btn-block btn-primary btn-lg col-2" (click)="showInputs()">
    {{ 'ssab.order.history.feedback.addnew'  | cxTranslate }}
  </button>


  <ng-container *ngIf="!hideInputs">
    <p>{{ 'ssab.order.history.feedback.description' | cxTranslate }}</p>
    <div class="feedback">
      <div class="row feedback-container">
        <ng-container *ngTemplateOutlet="feedbackSection;context:{id:FeedbackType.QUALITY}"></ng-container>
        <ng-container *ngTemplateOutlet="feedbackSection;context:{id:FeedbackType.DELIVERY}"></ng-container>
        <ng-container *ngTemplateOutlet="feedbackSection;context:{id:FeedbackType.SERVICE}"></ng-container>

        <div class="buttons d-flex col-4">
          <button type="submit" class="btn btn-primary" (click)="submit()">
            {{ 'common.submit'  | cxTranslate }}
          </button>

          <button type="submit" class="btn btn-secondary ml-4" (click)="cancel()">
            {{ 'common.cancel'  | cxTranslate }}
          </button>
        </div>
      </div>
    </div>

    <ng-template #feedbackSection let-id="id">
      <div class="col-3 rate-us">
        <p class="text-uppercase">{{ 'ssab.order.history.feedback.rate_' + id | cxTranslate }}</p>
        <span (mouseout)="starBlur(id)" *ngFor="let i of RATINGS" class="rating-input">
      <i
        [id]="'rating-star-'+id+'-'+i"
        [class]="'ssab-icon icon-md icon-inline icon-star rating-star-'+id"
        (click)="starClick($event, i, id)"
        (mouseover)="starFocus($event, i, id)"
      ></i>
    </span>
        <div class="invalid-feedback d-block text-right" *ngIf="isNotValid('rating_'+id)">
          <span>{{ 'ssab.order.history.feedback.mandatoryField'| cxTranslate }}</span>
        </div>
      </div>
      <div class="col-9">
        <div>
          {{ (isCommentMandatory(id) ? 'ssab.order.history.feedback.commentsMandatory' : 'ssab.order.history.feedback.comments') | cxTranslate }}
        </div>
        <ssab-cx-input
          [idName]="'comment_' + id"
          [nameForm]="'comment_'+id"
          [parentForm]="feedbackForm"
          [errorMessage]="'ssab.order.history.feedback.mandatoryComment'"
          [submitClicked]="submitClicked"
          [isTextArea]="true"
          (afterChange)="updateFormValidity()"
          [labelPlaceholder]="'ssab.order.history.feedback.placeholder'"
        ></ssab-cx-input>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<div class="feedback" *ngFor="let f of order.feedbacks">
  <div class="row">
    <div class="col-3">
      <p class="label">
        {{ 'ssab.order.history.feedback.reviewedOn'|cxTranslate }}
      </p>
      <div>
        {{ convertUIDate(f.commentedAt) |cxDate:  'longDate' }}
      </div>
    </div>

    <ng-container *ngFor="let ff of f.feedBacks">
      <div class="col-3">
        <p class="label">
          {{ 'ssab.order.history.feedback.rate_' + ff.id  | cxTranslate }}
        </p>
        <div>
        <span *ngFor="let i of RATINGS">
          <i
            [class]="'ssab-icon icon-md icon-inline icon-star'"
            [ngClass]="{'icon-star-selected': i <= ff.rating}"
          ></i>
       </span>
        </div>
      </div>
    </ng-container>
  </div>
</div>

