<ng-container *ngIf="getSsabCart() | async as cart">
  <ng-container *ngIf="!(cartLoaded$ | async) || cart.totalItems > 0; else emptyCartEntries">
    <ng-container *ngIf="cart.totalItems > 0;">
      <div class="cx-page-header-title">
        <h5 class="text-white pb-sm-4 pb-0">
          <a (click)="back()">
            <span class="ssab-icon icon-navigation-chevron-left icon-inline icon-white"></span>
            <span class="pl-2">{{ 'ssab.back' | cxTranslate }}</span>
          </a>
        </h5>
        <h2 class="text-white"> {{ (cart.quotationMode ? 'ssab.cart.checkout.titleQuote' : 'ssab.cart.checkout.title') | cxTranslate }}</h2>
      </div>

      <div class="items-details">
        <ng-container *ngIf="isConsignmentCart$ | async; else normalCartItemList">
          <ssab-cx-cart-item-list-consignment
            [readonly]="false"
            [items]="cart.entries"
            [cartIsLoading]="!(cartLoaded$ | async)"
            [cart]="cart"
            [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
           }"
            [showPricesOrConsignmentPricesStock]="showPricesOrConsignmentPricesStock$ | async"
          ></ssab-cx-cart-item-list-consignment>
        </ng-container>

        <ng-template #normalCartItemList>
          <ssab-cx-cart-item-list
            [readonly]="false"
            [hasGlobalDeliveryDate]="true"
            [items]="cart.entries"
            [cartIsLoading]="!(cartLoaded$ | async)"
            [cart]="cart"
            [options]="{
          isSaveForLater: false,
          optionalBtn: saveForLaterBtn
           }"
            [showPricesOrConsignmentPricesStock]="showPricesOrConsignmentPricesStock$ | async"
          ></ssab-cx-cart-item-list>
        </ng-template>

      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template let-ctx #saveForLaterBtn>
  <div
    *ngIf="selectiveCartEnabled"
    class="col-md-3 col-lg-3 col-xl-3 cx-sfl-btn"
  >
    <button
      class="link cx-action-link"
      [disabled]="ctx.loading"
      (click)="saveForLater(ctx.item)"
      type="button"
    >
      {{ 'saveForLaterItems.saveForLater' | cxTranslate }}
    </button>
  </div>
</ng-template>

<ng-template #emptyCartEntries>
  <div class="cx-page-header-title">
    <h5 class="text-white pb-sm-4 pb-3 mb-0">
      <a (click)="back()">
        <span class="ssab-icon icon-navigation-chevron-left icon-inline icon-white"></span>
        <span class="pl-2">{{ 'ssab.back' | cxTranslate }}</span>
      </a>
    </h5>
    <h2 class="text-white pb-sm-4 pb-0">{{ 'ssab.cart.checkout.title' | cxTranslate }}</h2>
  </div>

  <div class="header-block items-details">
    <div class="item-block">
      <div class="row item-header pb-0">
        <h3 class="m-0 p-0 col-12">{{ 'ssab.order.details' | cxTranslate }}</h3>

        <div class="empty-data-div">
          <div class="text-center">
            <span [innerHtml]="'ssab.cart.empty' | cxTranslate "></span>
            <button [routerLink]="{ cxRoute: 'search', params: { query: '' } } | cxUrl"
                    class="btn btn-primary btn-default-height pl-5 pr-5 mt-3">
              {{ 'miniCart.empty.search.products' | cxTranslate | uppercase}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
