import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SsabOccUserClaimAdapter} from "./ssab-occ-user-claim.adapter";
import {
  SsabClaim,
  SsabClaimAssigneeRequest,
  SsabClaimCreateRequest,
  SsabClaimList,
  SsabClaimRequest,
  SsabClaimStatusRequest
} from "../../model/claim.mode";
import {SsabRelatedDocumentList} from "../../model/document.model";

@Injectable({
  providedIn: 'root',
})
export class SsabUserClaimConnector {
  constructor(protected adapter: SsabOccUserClaimAdapter) {
  }

  public getClaimList(userId: string, params: SsabClaimRequest): Observable<SsabClaimList> {
    return this.adapter.loadClaimDocuments(userId, params);
  }

  public simulateClaimCreation(userId: string, params: SsabClaimCreateRequest): Observable<SsabClaim> {
    return this.adapter.simulateClaimCreation(userId, params);
  }

  public createClaim(userId: string, params: FormData): Observable<SsabClaim> {
    return this.adapter.createClaim(userId, params);
  }

  public getRelatedDocuments(claimId: string, orderId: string, customerId: string, complaintNumber?: string): Observable<SsabRelatedDocumentList> {
    return this.adapter.getRelatedDocuments(claimId, orderId, customerId, complaintNumber);
  }

  changeStatus(claimId: string, data: SsabClaimStatusRequest): Observable<void> {
    return this.adapter.changeStatus(claimId, data);
  }

  deleteClaim(claimId: string, userId: string): Observable<void> {
    return this.adapter.deleteClaim(claimId, userId);
  }

  changeAssignee(claimId: string, data: SsabClaimAssigneeRequest): Observable<void> {
    return this.adapter.changeAssignee(claimId, data);
  }

  sendMessage(claimId: string, message: string): Observable<SsabClaim> {
    return this.adapter.sendMessage(claimId, message);
  }

  setClaimMessagesRead(claimId: string): Observable<SsabClaim> {
    return this.adapter.setClaimMessagesRead(claimId);
  }
}
